import React, { useEffect, useState } from "react";
import moment from "moment";

interface Props {
  lastActivity: any[];
}

const ConnectionTime = ({ lastActivity }: Props) => {
  if (!lastActivity) return null;

  const [time, setTime] = useState(0);
  const [ timeSetted, setTimeSetted ] = useState(false)
  const [ isStillConnected, setIsStillConnected ] = useState(false)

  useEffect(() => {

    let seconds = 0

    lastActivity.forEach((activity: any, i: number) => {

      const isLastItem = i === lastActivity.length - 1

      if (activity.disconnectedAt) {

        const diff = moment(activity.disconnectedAt).diff(moment(activity.connectedAt), "s")

        seconds += diff
      } else if (isLastItem) {

        const diff = moment().diff(moment(activity.connectedAt), "s")

        seconds += diff

        setIsStillConnected(true)
      }
    })

    setTime(seconds)
    setTimeSetted(true)
  }, [])

  useEffect(() => {

    let interval: any

    const isOnline = lastActivity[lastActivity.length - 1].disconnectedAt ? false : true

    if (timeSetted && isOnline) {

      interval = setInterval(() => {

        setTime(time + 1);
      }, 1000);
    }

    return () => {

      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timeSetted, time]);

  return (
    <div>
      {time / 3600 >= 1 && (
        <>
          {(time / 3600).toFixed(0)} horas<br />
        </>
      )}
      {((time % 3600) / 60).toFixed(0)} minutos<br />
      {isStillConnected && <span className="text-success">Conectado</span>}
    </div>
  );
};

export default ConnectionTime;
