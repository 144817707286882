import React, { Fragment } from 'react';
import useTranslation from '../../../Hooks/UseTranslation';



type Props = {
    isNotRead: boolean,
    icon: any,
    data: any,
    onClick: any,
    timeDifference: string
}

const TeamMemberAddedNotification = (props: Props) => {

    const { t, language } = useTranslation();

    if (!props.data.team) {

        return null
    }

    return (
        <div
            // className="dropdown-item d-flex"
            className={`${props.isNotRead ? 'dropdownnotification' : ''} dropdown-item d-flex`}
            onClick={props.onClick}
        >
            <div className="notification-icon" style={{ padding: '10px' }}>
                {props.icon}
            </div>
            <div className="notification-details flex-grow-1">
                <p className="m-0 d-flex align-items-center">
                    <span>
                        {t('NOTIFICATIONS_TEAMMEMBER_ADDED:Project')}{' '}
                        {props.data.project.name}
                    </span>
                    <span
                        className={`badge badge-pill badge-muted ml-1 mr-1`}
                    >
                        {props.data.status === 'not_read' && (
                            <span className="badge badge-pill badge-primary ml-1 mr-1">
                                {t('NOTIFICATIONS_TEAMMEMBER_ADDED:Pending')}
                            </span>
                        )}
                    </span>
                    <span className="flex-grow-1"></span>
                    <span className="text-muted ml-auto">
                        {t('NOTIFICATIONS_TEAMMEMBER_ADDED:Ago')}{' '}
                        {props.timeDifference}
                    </span>
                </p>
                <p className=" text-muted m-0">
                    <span className='notification-content'>
                        {props.data.userTo._id === props.data.team_member._id && (
                            <span>
                                {language === 'es' && (
                                    <Fragment>
                                        {t('NOTIFICATIONS_TEAMMEMBER_ADDED:YouHaveBeenAssginedToTheProject')}{' '}
                                        <strong style={{ textTransform: 'uppercase' }}>
                                            {props.data.project.name}
                                        </strong>
                                    </Fragment>
                                )}
                                {language === 'en' && (
                                    <Fragment>
                                        <strong style={{ textTransform: 'uppercase' }}>
                                            {props.data.project.name}
                                        </strong>{' '}
                                        {t('NOTIFICATIONS_TEAMMEMBER_ADDED:YouHaveBeenAssginedToTheProject')}
                                    </Fragment>
                                )}
                            </span>
                        )}
                        {props.data.userTo._id !== props.data.team_member._id && (
                            <span>
                                <strong>
                                    {props.data.userFrom.name} {props.data.userFrom.surname}
                                </strong>{' '}
                                {t('NOTIFICATIONS_TEAMMEMBER_ADDED:HasAddedTo')}{' '}
                                <strong>
                                    {props.data.team_member.name} {props.data.team_member.surname}
                                </strong>{' '}
                                {t('NOTIFICATIONS_TEAMMEMBER_ADDED:TotheProject')}{' '}
                                <strong style={{ textTransform: 'uppercase' }}>
                                    {props.data.project.name}
                                </strong>
                            </span>
                        )}
                    </span>
                </p>
            </div>
        </div>
    )
}


export default TeamMemberAddedNotification