
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button,  Modal, Table } from "react-bootstrap";

import useTranslation from "../../Hooks/UseTranslation";

import Icon from "../Icon/Icon";
import OptionsControl from "../OptionsControl/OptionsControl";
import TasksActions from "../../Redux/Actions/TasksActions";
import { useDispatch } from "react-redux";

interface Props {
  show: boolean
  tasks: any[]
  onCancel: () => void
  onSaved: () => void
}

const UpdateDisplayModeMany = ({ show, tasks: originalTasks, onCancel, onSaved }: Props) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const daysRef: any = useRef<HTMLInputElement>(null)

  const [ bodyHeight, setBodyHeight ] = React.useState<number>(0)

  const [ mode, setMode ] = useState<'actual' | 'mixed' | 'scheduled' | undefined>(undefined)

  const [ tasks, setTasks ] = useState<any[]>([])

  const [ saving, setSaving ] = useState(false)

  const handleChangeMode = (mode: 'actual' | 'mixed' | 'scheduled') => {

    const tasks = originalTasks.map((task: any) => {

      if (mode === 'mixed') return task

      task.displayMode = mode

      return task
    })

    setTasks(tasks)
    setMode(mode)
  }

  const handleChangeModeToTask = (task_id: string, mode: 'actual' | 'mixed' | 'scheduled') => {

    tasks.map((_task: any) => {

      if (_task._id === task_id) {

        _task.displayMode = mode
      }

      return _task
    })

    setTasks(tasks)
  }

  const handleSave = async () => {

    const actualTasks = tasks.filter((task: any) => task.displayMode === 'actual').map((task: any) => task._id)
    const scheduledTasks = tasks.filter((task: any) => task.displayMode === 'scheduled').map((task: any) => task._id)

    await dispatch(TasksActions.updateDisplayModeMany(actualTasks, scheduledTasks))

    onSaved()
  }

  useEffect(() => {

    const getBodyHeight = () => {

      const modalHeight = window.innerHeight - 56
      const modalFooterHeight = 64

      const modalTitleHeight = 40

      const gridHeaderHeight = 0

      return modalHeight - modalFooterHeight - modalTitleHeight - gridHeaderHeight - 65
    }

    const handleResize = () => {

      const bodyHeight = getBodyHeight()

      setBodyHeight(bodyHeight)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {

    if (originalTasks.length === 0) return

    const areThereActual = originalTasks.some((task: any) => task.displayMode === 'actual')
    const areThereScheduled = originalTasks.some((task: any) => task.displayMode === 'scheduled')

    if (areThereActual && areThereScheduled) {

      setMode('mixed')
    } else if (areThereActual) {

      setMode('actual')
    } else if (areThereScheduled) {

      setMode('scheduled')
    }

    setTasks(originalTasks)
  }, [originalTasks])

  return (
    <Modal
      show={show}
      size="lg"
      backdrop
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>
          {t('UPDATE_DISPLAY_MODE_MANY:Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <OptionsControl
          options={[
            {
              icon: <Icon name="clock" far fw />,
              label: t('UPDATE_DISPLAY_MODE_MANY:OptionActualLabel'),
              value: 'actual',
            },
            {
              icon: (
                <Fragment>
                  <Icon name="clock" far />
                  <Icon name="calendar-check" far />
                </Fragment>
              ),
              label: t('UPDATE_DISPLAY_MODE_MANY:OptionMixedLabel'),
              value: 'mixed',
            },
            {
              icon: <Icon name="calendar-check" far fw />,
              label: t('UPDATE_DISPLAY_MODE_MANY:OptionScheduledLabel'),
              value: 'scheduled',
            },
          ]}
          selected={mode} onChange={handleChangeMode}
        />
        <div style={{
          overflowY: 'auto',
          maxHeight: bodyHeight,
        }}>
          <Table>
            <thead>
              <tr>
                <th>
                  {t('UPDATE_DISPLAY_MODE_MANY:TaskColumn')}
                </th>
                <th style={{ width: 400 }}>
                  {t('UPDATE_DISPLAY_MODE_MANY:ModeColumn')}
                </th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task, i) => (
                <tr key={task._id}>
                  <td>
                    {task.name}
                  </td>
                  <td>
                    <button
                      className={`btn btn-normal-2 ${task.displayMode === 'actual' ? 'active' : ''}`}
                      onClick={() => handleChangeModeToTask(task._id, 'actual')}
                      disabled={mode !== 'mixed'}
                    >
                      <Icon name="clock" far fw /> {t('UPDATE_DISPLAY_MODE_MANY:ActualMode')}
                    </button>{' '}
                    <button
                      className={`btn btn-normal-2 ${task.displayMode === 'scheduled' ? 'active' : ''}`}
                      onClick={() => handleChangeModeToTask(task._id, 'scheduled')}
                      disabled={mode !== 'mixed'}
                    >
                      <Icon name="calendar-check" far fw /> {t('UPDATE_DISPLAY_MODE_MANY:ScheduledMode')}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-secondary" onClick={onCancel}>
          <span className="icon-cancelar"></span> {' '}
          {t('UPDATE_DISPLAY_MODE_MANY:CancelButton')}
        </Button>
        <Button className="btn-primary" onClick={() => handleSave()} disabled={saving}>
          <span className="icon-guardar" ></span> {' '}
          {saving ? t('UPDATE_DISPLAY_MODE_MANY:SavingButton') : t('UPDATE_DISPLAY_MODE_MANY:SaveButton')}
        </Button>
      </Modal.Footer>
      </Modal>
  )
}

export default UpdateDisplayModeMany
