
import React from "react";
import { Redirect, Route, Router } from "react-router-dom";

import { ProjectProvider } from "./Hooks/UseProject";

import History from "./Helpers/History";
import PrivateRoute from "./Helpers/PrivateRoute";

import ChatPage from "./Pages/ChatPage/ChatPage";
import ConfirmPage from "./Pages/ConfirmPage/ConfirmPage";
import ForgotPage from "./Pages/ForgotPage/ForgotPage";
import HomePage from "./Pages/HomePage/HomePage";
import LoginPage from "./Pages/LoginPage/LoginPage";
import OtherProjectsPage from "./Pages/OtherProjectsPage/OtherProjectsPage";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import ProjectDashboardPage from "./Pages/App/Project/Home/DashboardPage/DashboardPage";
import ProjectInfoPage from "./Pages/App/Project/Home/InfoPage/ProjectInfoPage";
import ProjectTeamsPage from "./Pages/ProjectTeamsPage/ProjectTeamsPage";
import ProjectTasksPage from "./Pages/ProjectTasksPage/ProjectTasksPage";
import ProjectTasksArchivePage from "./Pages/ProjectTasksArchivePage/ProjectTasksArchivePage";
import MacroPage from "./Pages/ProjectProgrammation/MacroPage/MacroPage";
import LookaheadPage from "./Pages/ProjectProgrammation/LookaheadPage/LookaheadPage";
import WeeklyPage from "./Pages/ProjectProgrammation/WeeklyPage/WeeklyPage";
import ProjectProgrammationCalendarPage from "./Pages/ProjectProgrammationCalendarPage/ProjectProgrammationCalendarPage";
import ProjectResourcesRestrictionsPage from "./Pages/ProjectResourcesRestrictionsPage/ProjectResourcesRestrictionsPage";
import ProjectResourcesCNCPage from "./Pages/ProjectResourcesCNCPage/ProjectResourcesCNCPage";
import ProjectResourcesBalanceLetterPage from "./Pages/ProjectResourcesBalanceLetterPage/ProjectResourcesBalanceLetterPage";
import ProjectResourcesHHControlPage from "./Pages/ProjectResourcesHHControlPage/ProjectResourcesHHControlPage";
import ProjectIndicatorsPPCPage from "./Pages/ProjectIndicatorsPPCPage/ProjectIndicatorsPPCPage";
import ProjectIndicatorsCNCPage from "./Pages/ProjectIndicatorsCNCPage/ProjectIndicatorsCNCPage";
import RegisterPage from "./Pages/RegisterPage/RegisterPage";
import ResetPage from "./Pages/ResetPage/ResetPage";
import AdminHomePage from "./Pages/Admin/AdminHomePage/AdminHomePage";
import AdminMembershipsListPage from "./Pages/Admin/AdminMemberships/AdminMembershipsListPage";
import AdminMembershipsOrdersPage from "./Pages/Admin/AdminMemberships/AdminMembershipsOrdersPage";
import AdminMembershipsPlansPage from "./Pages/Admin/AdminMemberships/AdminMembershipsPlansPage";
import AdminMembershipsFeaturesPage from "./Pages/Admin/AdminMemberships/AdminMembershipsFeaturesPage";
import AdminUsersPage from "./Pages/Admin/AdminUsers/AdminUsersPage";
import AdminProjectsPage from "./Pages/Admin/AdminProjects/AdminProjectsPage";

const RedirectToProjectHome = (props: any) => {

  const project_id = props.match.params._id

  return (
    <Redirect to={`/project/${project_id}`} />
  )
}

const AppRouter = () => {

  return (
    <Router history={History}>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/register" component={RegisterPage} />
      <Route exact path="/confirm" component={ConfirmPage} />
      <Route exact path="/forgot-password" component={ForgotPage} />
      <Route exact path="/reset-password" component={ResetPage} />

      <PrivateRoute exact path="/" component={HomePage} />
      <PrivateRoute exact path="/chat" component={ChatPage} />
      <PrivateRoute exact path="/chat/:chat_id" component={ChatPage} />
      <PrivateRoute exact path="/other-projects" component={OtherProjectsPage} />

      <ProjectProvider>
        <PrivateRoute exact path="/project/:_id/" component={ProjectDashboardPage} />
        <PrivateRoute exact path="/project/:_id/info" component={ProjectInfoPage} />
        <PrivateRoute exact path="/project/:_id/teams/:team_id" component={ProjectTeamsPage} />
        <PrivateRoute exact path="/project/:_id/teams" component={ProjectTeamsPage} />
        <PrivateRoute exact path="/project/:_id/tasks" component={ProjectTasksPage} />
        <PrivateRoute exact path="/project/:_id/tasks-archive" component={ProjectTasksArchivePage} />
        <PrivateRoute exact path="/project/:_id/programmation" component={RedirectToProjectHome} />
        <PrivateRoute exact path="/project/:_id/programmation/:task_id" component={RedirectToProjectHome} />
        <PrivateRoute path="/project/:_id/programmation-macro" component={MacroPage} />
        <PrivateRoute exact path="/project/:_id/programmation-lookahead/:week" component={LookaheadPage} />
        <PrivateRoute exact path="/project/:_id/programmation-lookahead" component={LookaheadPage} />
        <PrivateRoute exact path="/project/:_id/programmation-weekly/:week" component={WeeklyPage} />
        <PrivateRoute exact path="/project/:_id/programmation-weekly" component={WeeklyPage} />
        <PrivateRoute exact path="/project/:_id/programmation-calendar" component={ProjectProgrammationCalendarPage} />
        <PrivateRoute exact path="/project/:_id/resources/restrictions" component={ProjectResourcesRestrictionsPage} />
        <PrivateRoute exact path="/project/:_id/resources/cnc/:week" component={ProjectResourcesCNCPage} />
        <PrivateRoute exact path="/project/:_id/resources/cnc" component={ProjectResourcesCNCPage} />
        <PrivateRoute exact path="/project/:_id/resources/balanceletter" component={ProjectResourcesBalanceLetterPage} />
        <PrivateRoute exact path="/project/:_id/resources/hhcontrol" component={ProjectResourcesHHControlPage} />
        <PrivateRoute exact path="/project/:_id/indicators/ppc" component={ProjectIndicatorsPPCPage} />
        <PrivateRoute exact path="/project/:_id/indicators/cnc" component={ProjectIndicatorsCNCPage} />
      </ProjectProvider>

      <PrivateRoute exact path="/profile" component={ProfilePage} />

      <PrivateRoute exact path="/admin" component={AdminHomePage} />
      <PrivateRoute exact path="/admin/memberships" component={AdminMembershipsListPage} />
      <PrivateRoute exact path="/admin/memberships/orders" component={AdminMembershipsOrdersPage} />
      <PrivateRoute exact path="/admin/memberships/plans" component={AdminMembershipsPlansPage} />
      <PrivateRoute exact path="/admin/memberships/features" component={AdminMembershipsFeaturesPage} />
      <PrivateRoute exact path="/admin/users" component={AdminUsersPage} />
      <PrivateRoute exact path="/admin/projects" component={AdminProjectsPage} />
    </Router>
  )
}

export default AppRouter
