
import React from "react";

import useTranslation from "../../Hooks/UseTranslation";

import { ProgrammationSchema } from "./types";

export type Props = {
  schema: ProgrammationSchema;
  show: boolean;
  dayWidth: number;
  maxDayWidth?: number;
  rowHeight: number;
  maxRowHeight?: number;
  canChangeRowHeight?: boolean;
  columns: any[];
  children?: any;
  showVerticalBorders: boolean;
  useCodeColors: boolean;
  hideNonWorkingDays: boolean;
  stretchUpToday: boolean;
  moveToScheduledDays: boolean;
  canShowBaseline: boolean;
  lineBaseTasks: boolean;
  hiddenColumns: string[];
  onUpdateSetting: (setting: string, value: any) => void;
  onToggleColumn?: (column: string, value: any) => void;
  onShowWorkflowsTriggered: () => void;
}

const Sidebar = (props: Props) => {

  const { t } = useTranslation()

  const dayWidthChange = props.schema === 'weekly' ? 24 : 4
  const dayWidthMinSize = props.schema === 'weekly' ? 72 : 24

  const decreaseDayWidth = (e: any) => {
    e.preventDefault()

    const newDayWidth = props.dayWidth - dayWidthChange

    if (newDayWidth >= dayWidthMinSize) {

      props.onUpdateSetting('dayWidth', newDayWidth)
    }
  }

  const increaseDayWidth = (e: any) => {
    e.preventDefault()

    const maxDayWidth = props.maxDayWidth || 64

    const newDayWidth = props.dayWidth + dayWidthChange

    if (newDayWidth <= maxDayWidth) {

      props.onUpdateSetting('dayWidth', newDayWidth)
    }
  }

  const increaseRowHeight = (e: any) => {
    e.preventDefault()

    const maxRowHeight = props.maxRowHeight || props.rowHeight

    const rowHeight = props.rowHeight + 32

    if (rowHeight <= maxRowHeight) {

      props.onUpdateSetting('rowHeight', rowHeight)
    }
  }

  const decreaseRowHeight = (e: any) => {
    e.preventDefault()

    const rowHeight = props.rowHeight - 32

    if (rowHeight >= 32) {

      props.onUpdateSetting('rowHeight', rowHeight)
    }
  }

  const isColumnHidden = (columnName: string) => {

    return props.hiddenColumns.includes(columnName)
  }

  const handleToggleColumn = (columnName: string, toggle: boolean) => {

    const hiddenColumns = [ ...props.hiddenColumns ]

    if (!toggle) {

      hiddenColumns.push(columnName)
    } else {

      const index = hiddenColumns.indexOf(columnName)

      hiddenColumns.splice(index, 1)
    }

    props.onUpdateSetting('columnsHidden', hiddenColumns)
  }

  return (
    <div className={`gantt-sidebar custom-scrollbar ${!props.show ? '' : 'open'}`}>
      <div className="form-group">
        <label htmlFor="">
          {t('GANTT_SIDEBAR:ColumnWidthLabel')}
        </label>
        <div className="d-flex flex-row">
          <button
            className="btn btn-secondary text-left mr-1"
            style={{ flex: 1 }}
            onClick={decreaseDayWidth}
          >
            <i className="fas fa-fw fa-minus"></i> {t('GANTT_SIDEBAR:DecreaseButton')}
          </button>
          <button
            className="btn btn-secondary text-left"
            style={{ flex: 1 }}
            onClick={increaseDayWidth}
          >
            <i className="fas fa-fw fa-plus"></i> {t('GANTT_SIDEBAR:IncreaseButton')}
          </button>
        </div>
      </div>
      {props.canChangeRowHeight && (
        <div className="form-group">
          <label htmlFor="">
            {t('GANTT_SIDEBAR:RowHeightLabel')}
          </label>
          <div className="d-flex flex-row">
            <button
              className="btn btn-secondary text-left mr-1"
              style={{ flex: 1 }}
              onClick={decreaseRowHeight}
            >
              <i className="fas fa-fw fa-minus"></i> {t('GANTT_SIDEBAR:DecreaseButton')}
            </button>
            <button
              className="btn btn-secondary text-left"
              style={{ flex: 1 }}
              onClick={increaseRowHeight}
            >
              <i className="fas fa-fw fa-plus"></i> {t('GANTT_SIDEBAR:IncreaseButton')}
            </button>
          </div>
        </div>
      )}
      <div className="form-group">
        <label htmlFor="">
          {t('GANTT_SIDEBAR:VisualizationMethodLabel')}
        </label>
          <div className="btn-group">
            <button className="btn btn-secondary" onClick={() => props.onShowWorkflowsTriggered()}>
              <i className="fas fa-grip-horizontal fw"></i> {' '}
              {t('GANTT_SIDEBAR:ShowWorkflowsButton')}
            </button>
          </div>
      </div>
      <div className="form-group">
        <label className="switch switch-right switch-primary">
          <span>
            {t('GANTT_SIDEBAR:ShowVerticalBordersLabel')}
          </span>
          <input className="form-check-input"
            type="checkbox"
            checked={props.showVerticalBorders}
            onChange={(e: any) => props.onUpdateSetting('showVerticalBorders', e.target.checked)}
            id="defaultCheck1"
          />
          <span className="slider"></span>
        </label>
      </div>
      <div className="form-group">
        <label className="switch switch-right switch-primary">
          <span>
            {t('GANTT_SIDEBAR:ShowCodeColorsLabel')}
          </span>
          <input className="form-check-input"
            type="checkbox"
            checked={props.useCodeColors}
            onChange={(e) => props.onUpdateSetting('useCodeColors', e.currentTarget.checked)}
            id="defaultCheck1"
          />
          <span className="slider"></span>
        </label>
      </div>
      <div className="form-group">
        <label className="switch switch-right switch-primary">
          <span>
            {t('GANTT_SIDEBAR:HideNonWorkingDaysLabel')}
          </span>
          <input className="form-check-input"
            type="checkbox"
            checked={props.hideNonWorkingDays}
            onChange={(e) => props.onUpdateSetting('hideNonWorkingDays', e.currentTarget.checked)}
            id="defaultCheck1"
          />
          <span className="slider"></span>
        </label>
      </div>
      <div className="form-group">
        <label className="switch switch-right switch-primary">
          <span>
            {t('GANTT_SIDEBAR:StretchActiveOverdueTasksLabel')}
          </span>
          <input className="form-check-input"
            type="checkbox"
            checked={props.stretchUpToday}
            onChange={(e) => props.onUpdateSetting('stretchUpToday', e.currentTarget.checked)}
            id="defaultCheck1"
          />
          <span className="slider"></span>
        </label>
      </div>
      {/* <div className="form-group">
        <label className="switch switch-right switch-primary">
          <span>
            {t('GANTT_SIDEBAR:MoveToScheduledDaysLabel')}
          </span>
          <input className="form-check-input"
            type="checkbox"
            checked={props.moveToScheduledDays}
            onChange={(e) => props.onUpdateSetting('moveToScheduledDays', e.currentTarget.checked)}
            id="defaultCheck1"
          />
          <span className="slider"></span>
        </label>
      </div> */}
      {props.canShowBaseline && (
        <div className="form-group">
          <label className="switch switch-right switch-primary">
            <span>
              {t('GANTT_SIDEBAR:ShowBaseLineLabel')}
            </span>
            <input className="form-check-input"
              type="checkbox"
              checked={props.lineBaseTasks}
              onChange={(e) => props.onUpdateSetting('lineBaseTasks', e.currentTarget.checked)}
              id="defaultCheck1"
            />
            <span className="slider"></span>
          </label>
        </div>
      )}
      <div className="form-group">
        <label htmlFor="">
          {t('GANTT_SIDEBAR:ShowColumnsLabel')}
        </label>
      </div>
      {props.columns.map((column: any, i: number) => (
        <div key={i} className="form-group">
          <label className="switch switch-right switch-primary">
            <span>{column.label}</span>
            <input className="form-check-input"
              type="checkbox"
              checked={!isColumnHidden(column.field)}
              onChange={(e) => handleToggleColumn(column.field, e.currentTarget.checked)}
            />
            <span className="slider"></span>
          </label>
        </div>
      ))}
  {/* {props.children} */}
    </div>
  )
}

export default Sidebar
