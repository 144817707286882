import React from "react";

import navigation from "../../navigation";

import { useProject } from "../../Hooks/UseProject";

import MainLayout from "../../Components/MainLayout/MainLayout";


const ProjectResourcesRestrictionsPage = (props: any) => {

    const { project_id, project } = useProject()

    const [ menu, submenu ] = navigation.getNavigation(
        project_id,
        'resources',
        'control-hh'
    )

    return (
        <MainLayout
            project={project}
            menuBar={menu}
            subMenuBar={submenu}
        >
            <h1>hello</h1>

        </MainLayout>
    )
}

export default ProjectResourcesRestrictionsPage