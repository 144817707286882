
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import useTranslation from "../../Hooks/UseTranslation";

import { exportToPdf } from "../../Services/ProgrammationService";

const FileDownloader = ({ fileUrl, fileName, btnLabel }: any) => {
  const handleDownload = async () => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download failed", error);
    }
  };

  return (
    <button onClick={handleDownload} className="btn btn-normal-2">
      <i className="fas fa-download"></i>{' '}
      {btnLabel}
    </button>
  )
};

const PdfExportModal = ({ show, onHide, project_id }: any) => {

  const { t } = useTranslation();

  const [ loading, setLoading ] = useState(true);
  const [ fileUrl, setFileUrl ] = useState('');
  const [ error, setError ] = useState('');

  useEffect(() => {

    const handleExport = async () => {

      setLoading(true);
      setError('');

      try {

        const { data: { fileUrl }} = await exportToPdf(project_id)

        setLoading(false);

        setFileUrl(fileUrl)
      } catch (error) {

        setLoading(false);

        if (error instanceof Error) {

          setError(error.message)
        }
      }

    }

    if (project_id)
      handleExport()
  }, [project_id])

  return (
    <Modal
      show={show}
      centered
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title>
          {t('PDF_EXPORT:Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          {loading && (
            <div className="spinner spinner-primary"></div>
          )}
          {!loading && fileUrl && (
            <FileDownloader fileUrl={fileUrl} fileName="macro.pdf" btnLabel={t('PDF_EXPORT:DownloadFileLabel')} />
          )}
          {error && (
            <div className="alert alert-danger">
              {error}
            </div>
          )}
        </div>
      </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={onHide}
            disabled={loading}
          >
            {t('PDF_EXPORT:CloseButton')}
          </button>
        </Modal.Footer>
    </Modal>
  )
}

export default PdfExportModal;
