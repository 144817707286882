
import React, { useEffect, useState } from 'react';
import { Modal, Nav, Tab } from 'react-bootstrap';
import { getActivity } from '../../../../../Services/AdminUsersService';
import moment from 'moment';

interface Props {
  show: boolean;
  user: any;
  onHide: () => void;
}

const ActivityModal = ({ show, user, onHide }: Props) => {

  const [ tab, setTab ] = useState('weekly')
  const [ activity, setActivity ] = useState<any>([])
  const [ loading, setLoading ] = useState(false)

  const [ weekly, setWeekly ] = useState<any>([])
  const [ daily, setDaily ] = useState<any>([])

  const handleClose = () => {

    setActivity([])

    onHide()
  }

  useEffect(() => {

    const loadActivity = async () => {

      if (!user || !user._id) return

      setLoading(true)

      const { data, error } = await getActivity(user._id)

      setLoading(false)

      if (error) {
        console.error(error)
        return
      }

      setActivity(data)
    }

    loadActivity()
  }, [ user ])

  useEffect(() => {

    const getWeekly = () => {

      const weekly: any[] = []

      activity.forEach((item: any, i: number) => {

        let seconds = 0

        const week = weekly.find((w: any) => w.week === moment(item.connectedAt).format('YYYYWW'))
        const isLastItem = i === activity.length - 1

        if (item.disconnectedAt) {

          const diff = moment(item.disconnectedAt).diff(moment(item.connectedAt), "s")

          seconds += diff
        } else if (isLastItem) {

          const diff = moment().diff(moment(item.connectedAt), "s")

          seconds += diff
        }

        item.seconds = seconds
        item.count = 1

        if (week) {
          week.seconds += item.seconds
          week.count += item.count
        } else {
          weekly.push({
            week: moment(item.connectedAt).format('YYYYWW'),
            seconds: item.seconds,
            count: item.count,
          })
        }

      })

      weekly.sort((a: any, b: any) => a.week - b.week)

      return weekly
    }

    const getDaily = () => {

      const daily: any[] = []

      activity.forEach((item: any, i: number) => {

        let seconds = 0

        const day = daily.find((d: any) => d.day === moment(item.connectedAt).format('YYYY-MM-DD'))
        const isLastItem = i === activity.length - 1

        if (item.disconnectedAt) {

          const diff = moment(item.disconnectedAt).diff(moment(item.connectedAt), "s")

          seconds += diff
        } else if (isLastItem) {

          const diff = moment().diff(moment(item.connectedAt), "s")

          seconds += diff
        }

        item.seconds = seconds
        item.count = 1

        if (day) {
          day.seconds += item.seconds
          day.count += item.count
          day.sessions.push(item)
        } else {
          daily.push({
            day: moment(item.connectedAt).format('YYYY-MM-DD'),
            seconds: item.seconds,
            count: item.count,
            sessions: [item]
          })
        }
      })

      daily.sort((a: any, b: any) => a.day - b.day)

      return daily
    }

    setWeekly(getWeekly())
    setDaily(getDaily())
  }, [activity])

  console.log('activity', activity)
  console.log('weekly', weekly)
  console.log('daily', daily)

  return (
    <Modal
      show={show}
      centered
      onHide={handleClose}
    >
      <Modal.Body>
        <Tab.Container defaultActiveKey='weekly'>
          <div className="ul-widget__head">
            <div className="ul-widget__head-label flex-grow-1">
              <h4 className="ul-widget__head-title">
                Actividad de {user.name} {user.surname}
              </h4>
            </div>
            <div className="ul-widget__head-nav">
              <Nav variant='tabs' className='nav-status-completada'
                activeKey='weekly'
                defaultActiveKey='weekly'
                onSelect={(k) => setTab(k || '')}
              >
                <Nav.Item>
                  <Nav.Link eventKey='weekly'>
                    Resumen
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='daily'>
                    Historial
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <div className='ul;-widget__body'>
            <Tab.Content>
              <Tab.Pane eventKey='weekly'>
                <h4>
                  Resumen Semanal {(weekly.reduce((a: any, b: any) => a + b.seconds, 0) / 60).toFixed(0)} min
                </h4>
                <ul>
                  {weekly.map((week: any, index: number) => (
                    <li key={index}>
                      <span>Semana {index + 1}: </span>
                      <span>{(week.seconds / 60).toFixed(0)} min</span>
                    </li>
                  ))}
                </ul>
              </Tab.Pane>
              <Tab.Pane eventKey='daily'>
                <h4>
                  Historial de sesiones: {(daily.reduce((a: any, b: any) => a + b.count, 0))} sesiones
                </h4>
                <ul>
                  {daily.map((day: any, index: number) => (
                    <li key={index}>
                      <span>{moment(day.day).format('dddd DD.MM.YY')}: </span>
                      <ul>
                        {day.sessions.map((session: any, i: number) => (
                          <li key={i}>
                            <span>
                              Sesión {i + 1}: {moment(session.connectedAt).format('H:mm:ss')} - {moment(session.disconnectedAt).format('H:mm:ss')}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onHide}>Cerrar</button>
      </Modal.Footer>
    </Modal>
  )
}

export default ActivityModal

