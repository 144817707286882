
enum ListItemTypes {
  group,
  task,
}

interface IState {
  loadStatus: 'none' | 'loading' | 'loaded' | 'error',
  list: any[]
  tasks: any[]
  users: any[]
  colorCodes: any[]
  relations: any[]
  versions: any[]
  error: string
}

const initialState: IState = {
  loadStatus: 'none',
  list: [],
  tasks: [],
  users: [],
  colorCodes: [],
  relations: [],
  versions: [],
  error: ''
}

const ProgrammationReducer = (state: IState = initialState, { type, payload, error }: any) => {

  const st = { ...state }

  if (type === 'PROGRAMMATION_LOAD_START') {

    st.loadStatus = 'loading'
  } else if (type === 'PROGRAMMATION_LOAD_END') {

    st.loadStatus = 'loaded'
    st.list = payload.list
    st.tasks = payload.tasks
    st.users = payload.users
    st.colorCodes = payload.colorCodes
    st.relations = payload.relations
    st.versions = payload.versions
  } else if (type === 'PROGRAMMATION_LOAD_ERROR') {

    st.loadStatus = 'error'
    st.error = error
  } else if (type === 'PROGRAMMATION_ADD_TASK') {

    st.loadStatus = 'loaded'
    st.list = payload.list

    const tasks = st.tasks
    tasks.push(payload.task)

    st.tasks = tasks
  } else if (type === 'PROGRAMMATION_UPDATE_TASK') {

    const tasks = st.tasks.map((task: any) => {

      if (task._id === payload.task._id) {

        return payload.task
      }

      return task
    })

    if (payload.list)
      st.list = payload.list

    st.tasks = tasks
    st.loadStatus = 'loaded'
  } else if (
    type === 'PROGRAMMATION_CONFIRM_REJECT_TASK' ||
    type === 'PROGRAMMATION_UPDATE_DISPLAY_MODE_TASK'
  ) {

    const tasks = st.tasks.map((task: any) => {

      if (task._id === payload.task._id) {

        return payload.task
      }

      return task
    })

    st.tasks = tasks
    st.loadStatus = 'loaded'
  } else if (
    type === 'PROGRAMMATION_COMPLETE_MANY' ||
            type === 'PROGRAMMATION_RESCHEDULE_MANY' ||
            type === 'PROGRAMMATION_RESTRICT_MANY' ||
            type === 'PROGRAMMATION_UPDATE_TASKS'
  ) {

    const tasks = st.tasks.map((task: any) => {

      const modified = payload.tasks.find((ft: any) => ft._id === task._id)

      if (modified) {

        return modified
      }

      return task
    })

    st.tasks = tasks
    st.loadStatus = 'loaded'
  } else if (type === 'PROGRAMMATION_UPDATE_LIST') {

    st.list = payload.list
    st.loadStatus = 'loaded'
  } else if (type === 'PROGRAMMATION_REMOVE_TASK') {

    st.list = payload.list
    st.tasks = st.tasks.filter((task: any) => task._id !== payload.task_id)
    st.loadStatus = 'loaded'
  } else if (type === 'PROGRAMMATION_DELETE_GROUP') {

    st.list = payload.list
    st.tasks = st.tasks.filter((task: any) => !payload.deleted_tasks_ids.includes(task._id))
    st.loadStatus = 'loaded'
  } else if (type === 'PROGRAMMATION_CLEANUP') {

    st.loadStatus = 'none'
    st.list = []
    st.tasks = []
    st.users = []
    st.colorCodes = []
    st.relations = []
    st.versions = []
    st.error = ''
  }

  return st
}

export default ProgrammationReducer
