
import React from 'react'

import './OptionsControl.scss'

interface Option {
    icon?: JSX.Element,
    label: string,
    value: any,
}

interface Props {
    options: Option[],
    selected: any,
    onChange: any,
}

// must be linear options as buttons
const OptionsControl = ({ options, selected, onChange }: Props) => {

    return (
        <div className="options-control">
            {options.map((option: Option, i: number) => (
                <button
                    key={i}
                    type="button"
                    className={`option-button ${option.value === selected ? 'active' : ''}`}
                    onClick={() => onChange(option.value)}
                >
                  {option.icon}
                  <div className='option-label'>
                    {option.label}
                  </div>
                </button>
            ))}
        </div>
    )
}

export default OptionsControl
