
import config from '../config'

import { request, EMethods, post, get, put, del } from './ApiService'

export const loadTasks = async (project_id: string, userFilters?: ITaskListFilters) => {

    const defFilters = {
      user: '',
      state: '',
      team: '',
      group: '',
      code: '',
      type: '',
      start: '',
      end: '',
    }

    const filters = {
      ...defFilters,
      ...userFilters
    }

    const filterParts = Object.entries(filters)
        .filter(([ key, value ]) => value !== '')
        .map(([ key, value ]) => `${key}=${value}`)
        .join('&')

    const url = `${config.apiHost}/task-list/${project_id}/macro?${filterParts}`

    return await get(url, 'GET_TASKS')
}

const getArchive = async (project_id: string) => {

    const url = config.apiHost + '/tasks/archive?project_id=' + project_id

    try {

        const response = await request(
            EMethods.get,
            'TASKS_GET_ARCHIVE',
            url,
        )

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const createTask = async (task: any) => {

    const url = config.apiHost + '/tasks'

    try {

        const response = await request(
            EMethods.post,
            'TASKS_CREATE',
            url,
            task
        )

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const updateTask = async (task: any) => {

    const url = config.apiHost + '/tasks/' + task._id

    try {

        const response = await request(
            EMethods.put,
            'TASKS_UPDATE',
            url,
            task
        )

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const completeMany = async (tasks: any, project: string, displayMode: 'scheduled' | 'actual') => {

    const url = config.apiHost + '/tasks/complete-many'

    return await put(url, { tasks, project, displayMode }, 'TASKS_COMPLETE_MANY')
}

const reScheculeMany = async (tasks: any) => {

    const url = config.apiHost + '/tasks/reschedule-many'

    return await put(url, { tasks }, 'TASKS_COMPLETE_MANY')
}

const updateDisplayModeSingle = async (task_id: string, displayMode: string) => {

    const url = config.apiHost + '/tasks/' + task_id + '/display-mode'

    return await put(url, { mode: displayMode }, 'TASK_UPDATE_DISPLAY_MODE')
}

const updateDisplayModeMany = async (actual: string[], scheduled: string[]) => {

    const url = config.apiHost + '/tasks/update-displaymode-many'

    return await put(url, { actual, scheduled }, 'TASKS_COMPLETE_MANY')
}

const addRestrictionToMany = async (tasks: string[], restriction: string) => {

    const url = `${config.apiHost}/tasks/add-restriction-to-many`

    return await post(url, { tasks, restriction }, 'TASK_LIST_ADD_RESTRICTION_TO_MANY')
}

const deleteTask = async (task: any, project_id: string) => {

    const url = config.apiHost + '/tasks/' + task._id + '?project_id=' + project_id

    try {

        const res = await request(EMethods.delete, 'TASK_DELETE', url)

        return res.data
    } catch (error) {

        throw error
    }
}

const confirmOrReject = async (task_id: string, action: 'confirm' | 'reject') => {

    const url = config.apiHost + '/tasks/' + task_id + '/confirm-or-reject'

    try {

        const res = await request(EMethods.post, 'TASK_CONFIRM_OR_REJECT', url, { confirm: action === 'confirm' })

        return res.data
    } catch (error) {

        throw error
    }
}

export const updateRestriction = async (restriction_id: string, checked: boolean) => {

    const url = `${config.apiHost}/tasks/restrictions/${restriction_id}`

    const body = {
        checked,
    }

    return await put(url, body, 'RESTRICTION_UPDATE')
}

export const removeRestriction = async (restriction_id: string) => {

    const url = config.apiHost + '/tasks/restrictions/' + restriction_id

    return await del(url, 'RESTRICTION_DELETE')
}

export const createComment = async (task_id: string, user_id: string, comment: string) => {

    const url = config.apiHost + '/tasks/' + task_id + '/comments'

    try {

        const res = await request(EMethods.post, 'COMMENT_CREATE', url, { user_id, comment })

        return res.data
    } catch (error) {

        throw error
    }
}

export const removeUserFromTask = async (user_id: string, task_id: string) => {

    // TODO: use a better endpoint url like: `${config.apiHost}/task/${task_id}/remove-user`
    const url = `${config.apiHost}/task-list/remove-user-from-task`

    const postData = {
        user_id,
        task_id
    }

    return await post(url, postData, 'TASK_LIST_REMOVE_USER_FROM_TASK')
}

export default {
    getArchive,
    createTask,
    updateTask,
    completeMany,
    reScheculeMany,
    updateDisplayModeSingle,
    updateDisplayModeMany,
    addRestrictionToMany,
    deleteTask,
    confirmOrReject,
    removeRestriction,
}
