
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Card } from "react-bootstrap";

import config from "../../../config";
import navigation from "../../../navigation";
import { TaskWithId } from "../../../Types/Tasks";

import { useAuth } from "../../../Hooks/UseAuth";
import useTranslation from "../../../Hooks/UseTranslation";
import { useProject } from "../../../Hooks/UseProject";

import { getWeekNavigator } from "../../../Helpers/LookaheadHelper";
import TeamsHelper from "../../../Helpers/TeamsHelper";
import {
  IGetItemsListOptions,
  filterByColorCodes,
  filterByGroups,
  filterByStatus,
  filterByTypes,
  filterByUsers,
  getCollapsedGroups,
  getGanttItems,
  getItemFromTaskList,
  getLookaheadResult,
  getNonWorkingDays,
  saveCollapsedGroups
} from "../../../Helpers/ProgrammationHelper";

import ColorCodeActions from "../../../Redux/Actions/ColorCodeActions";
import ProjectsActions from "../../../Redux/Actions/ProjectsActions";
import TasksActions from "../../../Redux/Actions/TasksActions";
import TeamsActions from "../../../Redux/Actions/TeamsActions";
import {
  regenerateLookahead,
  setLookaheadAction,
  setLookaheadCollapsedGroupsAction,
  setLookeaheadGanttItemsAction
} from "../../../Redux/Actions/LookaheadActions";
import InterfaceActions from "../../../Redux/Actions/InterfaceActions";
import { loadProgrammationAction } from "../../../Redux/Actions/ProgrammationActions";

import MainLayout from "../../../Components/MainLayout/MainLayout";
import MainLayoutContentHeader from "../../../Components/MainLayout/MainLayoutContentHeader";
import MainLayoutContentBody from "../../../Components/MainLayout/MainLayoutContentBody";
import Gantt from "../../../Components/GantV2/Gantt";
import { GanttColumn } from "../../../Components/GantV2/types";
import { getStatusName } from "../../../Components/TaskManager/TaskEditorHelper";
import UserAvatar from "../../../Components/UserAvatar/UserAvatar";
import TaskFilters from "../../../Components/TaskFilters/TaskFilters";
import TaskEditor from "../../../Components/TaskManager/TaskEditor";
import Icon from "../../../Components/Icon/Icon";

const LookaheadPage = (props: any) => {

  const { t } = useTranslation()

  const sidebarRefBtn = useRef<HTMLButtonElement>(null)

  const week = props.match.params.week

  const { userData, ability } = useAuth()

  const dispatch = useDispatch()

  const { project_id, project } = useProject()

  const projectLoadStatus = useSelector((state: any) => state.ProjectsReducer.loadRowStatus)
  const teams = useSelector((state: any) => state.TeamsReducer.result)
  const programmation = useSelector((state: any) => state.ProgrammationReducer)
  const lookahead = useSelector((state: any) => state.LookaheadReducer)

  const teamsMembersIds = useMemo(() => TeamsHelper.getAllMembersFromProject(teams).map((user: any) => user._id), [ teams ])

  const [ showTaskFilters, setShowTaskFilters ] = useState(false)

  const savedFilters = localStorage.getItem(`${project_id}-lookahead-filters`)

  const [ filters, setFilters ] = useState(savedFilters ? JSON.parse(savedFilters) : {
    user: '',
    state: '',
    team: '',
    group: '',
    code: '',
    type: '',
    start: '',
    end: '',
  })

  const [ mySettings, setMySettings ] = useState(project?.mySettings)
  const [ showSidebar, setShowSidebar ] = useState(false)
  const [ showTaskEditor, setShowTaskEditor ] = useState(false)
  const [ showGroupEditor, setShowGroupEditor ] = useState(false)

  const [ task, setTask ] = useState<TaskWithId | undefined>()
  const [ group, setGroup ] = useState({}) //TODO: use undefined instead of empty object
  const [ parentId, setParentId ] = useState('')

  const navigator = useMemo(() => {

    if (!project._id) return {
      prev: '',
      next: '',
      startDay: '',
      endDay: '',
      week: 0,
      range: []
    }

    return getWeekNavigator(project.startsAt, project.realEnd, project.settings.lookahead, week)
  }, [ project, week ])

  const navigateToWeek = (week?: any) => {

    dispatch(regenerateLookahead())

    if (week) {

      props.history.push(`/project/${project_id}/programmation-lookahead/${week}`)
    } else {

      props.history.push(`/project/${project_id}/programmation-lookahead`)
    }
  }

  const nonWorkingDays = useMemo(() => {

    if (!project._id) return []

    return getNonWorkingDays(lookahead.start, lookahead.end, project)
  }, [ project._id, lookahead.start, lookahead.end ])

  const [ menu, submenu ] = navigation.getNavigation(
    props.match.params._id,
    'programmation',
    'lookahead'
  )

  const columns: GanttColumn[] = [
    {
      label: t('LOOKAHEAD:TypeColumn'),
      field: 'type',
      width: 50,
      render: (field: any) => !field ? null : field.substring(0, 1).toUpperCase()
    },
    {
      label: t('LOOKAHEAD:ColorCodeColumn'),
      field: 'colorCodeName',
      width: 60,
    },
    {
      label: t('LOOKAHEAD:PositionColumn'),
      field: 'position',
      width: 100,
    },
    {
      label: t('LOOKAHEAD:TitleColumn'),
      field: 'name',
      width: 300,
    },
    {
      label: t('LOOKAHEAD:StatusColumn'),
      field: 'status',
      width: 100,
      render: (field: any) => getStatusName(field)
    },
    {
      label: t('LOOKAHEAD:StartsAtColumn'),
      field: 'startsAt',
      width: 80,
      render: (field: any) => field ? moment(field).format('DD/MM/YYYY') : null
    },
    {
      label: t('LOOKAHEAD:EndsAtColumn'),
      field: 'endsAt',
      width: 80,
      render: (field: any) => field ? moment(field).format('DD/MM/YYYY') : null
    },
    {
      label: t('LOOKAHEAD:ResponsiblesColumn'),
      field: 'responsibles',
      width: 80,
      render: (field: any, item: any) => {

        const isField = !!field
        const isFieldArray = isField && Array.isArray(field)

        if (isField && isFieldArray) {

          const responsibles = field.filter((user: any) => teamsMembersIds.includes(user._id))

          return responsibles.map((user: any) => <UserAvatar key={user._id} user={user} size="xs" />)
        }

        return null
      }
    },
    {
      label: t('LOOKAHEAD:ReleasesAtColumn'),
      field: 'releasesAt',
      width: 80,
      render: (field: any) => field ? moment.utc(field).format('DD/MM/YYYY') : null
    },
    {
      label: t('LOOKAHEAD:ExecuteDaysColumn'),
      field: 'executeDays',
      width: 60,
    },
    {
      label: t('LOOKAHEAD:PeriodDaysColumn'),
      field: 'periodDays',
      width: 100,
    },
    {
      label: t('LOOKAHEAD:ExecutePercentColumn'),
      field: 'executePercent',
      width: 60,
    },
    {
      label: t('LOOKAHEAD:UnitColumn'),
      field: 'unit',
      width: 60,
    },
    {
      label: t('LOOKAHEAD:MeteredColumn'),
      field: 'metered',
      width: 60,
      render: (field: any) => field || ''
    },
    {
      label: t('LOOKAHEAD:PerformanceColumn'),
      field: 'performance',
      width: 60,
      render: (field: any) => field || ''
    },
    {
      label: t('LOOKAHEAD:CrewNumberColumn'),
      field: 'crewNumber',
      width: 60,
      render: (field: any) => field || ''
    },
    {
      label: t('LOOKAHEAD:EstimatedDaysColumn'),
      field: 'estimatedDays',
      width: 60,
      render: (field: any) => field || ''
    },
    {
      label: t('LOOKAHEAD:ActualStartColumn'),
      field: 'startedAt',
      width: 90,
      render: (field: any) => field ? moment(field).format('DD/MM/YYYY') : null
    },
    {
      label: t('LOOKAHEAD:ActualEndColumn'),
      field: 'endedAt',
      width: 90,
      render: (field: any) => field ? moment(field).format('DD/MM/YYYY') : null
    },
    {
      label: t('LOOKAHEAD:RestrictionsColumn'),
      field: 'restrictions',
      width: 90,
      render: (field: any) => !field ? null : `${field.done}/${field.toDo}`
    },
    {
      label: t('LOOKAHEAD:CommentsColumn'),
      field: 'comments',
      width: 150,
      render: (field: any) => {

        if (!field) return null

        return `${field.comment}...`
      }
    }
  ]

  const loadTeams = async () => {

    await dispatch(TeamsActions.getResultAction(project_id))
  }

  const loadProgrammation = async () => {

    await dispatch(loadProgrammationAction(project_id))
  }

  const loadLookahead = (withFilters?: any) => {

    if (programmation.loadStatus !== 'loaded') return

    const options = {
      ...programmation,
      list: JSON.parse(JSON.stringify(programmation.list)),
      tasks: JSON.parse(JSON.stringify(programmation.tasks)),
      navigator,
    }

    let lookaheadResult = getLookaheadResult(options)

    const newFilters = withFilters || filters

    if (newFilters.team) {

      const teams_ids = newFilters.team.split(',')

      const teamsFound = teams.filter((team: any) => teams_ids.includes(team._id))

      const users_ids: string[] = []

      teamsFound.forEach((team: any) => {

        team.users.forEach((user: any) => {

          if (!users_ids.includes(user._id))
            users_ids.push(user._id)
        })
      })

      lookaheadResult = filterByUsers(lookaheadResult, users_ids)
    } else if (newFilters.user)
      lookaheadResult = filterByUsers(lookaheadResult, newFilters.user.split(','))

    if (newFilters.group) {

      lookaheadResult = filterByGroups(lookaheadResult, newFilters.group.split(','))
    } else if (newFilters.code)
      lookaheadResult = filterByColorCodes(lookaheadResult, newFilters.code.split(','))

    if (newFilters.type)
      lookaheadResult = filterByTypes(lookaheadResult, newFilters.type.split(','))

    if (newFilters.state)
      lookaheadResult = filterByStatus(lookaheadResult, newFilters.state.split(','))

    const collapsedGroups = getCollapsedGroups(project_id, 'lookahead')

    dispatch(setLookaheadAction(lookaheadResult, navigator.startDay, navigator.endDay, navigator.week, collapsedGroups))
  }

  const loadColorCodes = async () => {

    await dispatch(ColorCodeActions.getResult(project._id))
  }

  const hasActiveFilters = () => {

    const values = Object.values(filters)
    const found = values.findIndex(f => f !== '')

    return !!(found >= 0)
  }

  const handleChangeFilters = (filters: any) => {

    localStorage.setItem(`${project_id}-lookahead-filters`, JSON.stringify(filters))

    loadLookahead(filters)
    setFilters(filters)
  }

  const handleEditItem = async (item_id: string) => {

    const { item, parent } = getItemFromTaskList(lookahead.result, item_id)

    if (item.items) {

      setGroup(item || {})
      setShowGroupEditor(true)
    } else {

      setTask(item)
      setGroup(parent || {})
      setShowTaskEditor(true)
    }

    setParentId(parent?._id || '')
  }

  const handleCloseTaskEditor = () => {

    setTask(undefined)
    setGroup({})
    setShowTaskEditor(false)
  }

  const handleTaskUpdated = async () => {

    setShowTaskEditor(false)
  }

  const handleConfirmedOrRejected = async (task_id: string, action: 'confirm' | 'reject') => {

    await dispatch(TasksActions.confirmOrReject(task_id, action))

    setShowTaskEditor(false)
  }

  const handleTaskDeleted = async (task: any) => {

    //TODO: pass the dispatch action to task editor
    await dispatch(TasksActions.remove(task, project._id))

    setShowTaskEditor(false)
  }

  const handleToggleCollapse = async (group_id: string) => {

    const collapsedGroups = getCollapsedGroups(project_id, 'lookahead')

    if (collapsedGroups.includes(group_id)) {

      collapsedGroups.splice(collapsedGroups.indexOf(group_id), 1)
    } else {

      collapsedGroups.push(group_id)
    }

    saveCollapsedGroups(project_id, 'lookahead', collapsedGroups)

    dispatch(setLookaheadCollapsedGroupsAction(collapsedGroups))
  }

  const handleShowWorkflows = (groups: string[]) => {

    saveCollapsedGroups(project_id, 'lookahead', groups)

    dispatch(setLookaheadCollapsedGroupsAction(groups))
  }

  const updateSettings = async (settings = mySettings) => {

    let areDifferent = false

    for (const key in settings) {

      if (key !== 'setted' && settings[ key ] !== project.mySettings[ key ]) {

        areDifferent = true
      }
    }

    if (areDifferent) {

      await dispatch(ProjectsActions.updateSettings(project.mySettings._id, settings))
    }

    setMySettings(settings)
  }

  const toggleSidebar = (target?: any) => {

    if (!target) {

      if (showSidebar) updateSettings()

      setShowSidebar(!showSidebar)
    } else {

      if (sidebarRefBtn.current) {

        const isButtonClicked = sidebarRefBtn.current.contains(target)

        if (!isButtonClicked) {

          // trigger click because if not mySettings is not updated
          sidebarRefBtn.current.click()
        }
      }
    }
  }

  const handleUpdateSetting = (key: string, value: any) => {

    let field = key

    if (field === 'dayWidth') {

      field = 'ganttLookaheadColumnSize'
    } else if (field === 'hideNonWorkingDays') {

      field = 'ganttLookaheadHideNonWorkingDays'
    } else if (field === 'showVerticalBorders') {

      field = 'ganttLookaheadShowVerticalBorders'
    } else if (field === 'columnsHidden') {

      field = 'ganttHideLookaheadFields'
    } else if (field === 'divisorPosition') {

      field = 'ganttLookaheadDivisorPosition'
    }

    updateSettings({
      ...mySettings,
      [ field ]: value
    })
  }

  useEffect(() => {

    if (projectLoadStatus === 'loaded') {

      loadTeams()
      loadColorCodes()

      setMySettings(project.mySettings)
    }
  }, [ projectLoadStatus ])

  useEffect(() => {

    if (projectLoadStatus === 'loaded') {

      if (project.ability) ability.update(project.ability)

      if (programmation.loadStatus === 'none') {

        loadProgrammation()
      }
    }
  }, [ projectLoadStatus, programmation.loadStatus ])

  useEffect(() => {

    if (programmation.loadStatus !== 'loaded') return

    if (lookahead.loadStatus === 'none') {

      loadLookahead()
    }
  }, [ programmation.loadStatus, lookahead.loadStatus ])

  useEffect(() => {

    if (lookahead.loadStatus !== 'loaded') return
    if (lookahead.loadGanttItemsStatus !== 'none') return

    const options: IGetItemsListOptions = {
      itemsList: lookahead.result,
      collapsedGroups: lookahead.collapsedGroups,
      schema: 'lookahead',
      nonWorkingDays,
      stretchUpToday: mySettings?.stretchUpToday,
      moveToScheduledDays: mySettings?.moveToScheduledDays,
      showOnlyThisUserTasks: project.mySettings.showOnlyMyTasks ? userData?.id : undefined,
      week: navigator.week,
      ganttStart: lookahead.start,
      ganttEnd: lookahead.end,
    }

    const ganttItems = getGanttItems(options);

    // cortamos las barras de los items que se salen del rango de fechas
    ganttItems.forEach((item: any) => {

      item.barStart = moment(item.barStart).isBefore(moment(lookahead.start)) ? lookahead.start : item.barStart;
      item.barEnd = moment(item.barEnd).isAfter(moment(lookahead.end)) ? lookahead.end : item.barEnd;

      item.bars?.forEach((bar: any) => {

        bar.barStart = moment(bar.barStart).isBefore(moment(lookahead.start)) ? lookahead.start : bar.barStart;
        bar.barEnd = moment(bar.barEnd).isAfter(moment(lookahead.end)) ? lookahead.end : bar.barEnd;
      })
    })

    dispatch(setLookeaheadGanttItemsAction(ganttItems))
  }, [ lookahead.loadGanttItemsStatus, lookahead.result, mySettings ]);

  useEffect(() => {

    const projectLoaded = projectLoadStatus === 'loaded'
    const taskListLoaded = lookahead.loadStatus === 'loaded'

    if (!projectLoaded || !taskListLoaded) {

      dispatch(InterfaceActions.showLoader())
    } else {

      dispatch(InterfaceActions.hideLoader())
    }
  }, [ projectLoadStatus, lookahead.loadStatus ])

  return (
    <MainLayout
      username={`${userData?.name} ${userData?.surname}`}
      project={project}
      menuBar={menu}
      subMenuBar={submenu}
      rightTitle={project.shortName}
      avatar={userData?.avatar ? `${config.apiHost}/${userData.avatar}` : ''}
    >
      <MainLayoutContentHeader>
        <div className='ul-widget__head-label flex-grow-1'>
          <h3 className='ul-widget__head-title'>
            <span>
              {t('LOOKAHEAD:Title')}
            </span>
            {(lookahead.start !== '' && lookahead.end !== '') && (
              <small className="d-block d-sm-inline">
                ({t('LOOKAHEAD:DateFrom')} {' '}
                {moment(lookahead.start).format('DD/MM/YYYY')} {' '}
                {t('LOOKAHEAD:DateTo')} {' '}
                {moment(lookahead.end).format('DD/MM/YYYY')})
              </small>
            )}
          </h3>
        </div>
        <div className="ul-widget__head-toolbar">
          <button className={`btn btn-link btn-link-header ${lookahead.loadStatus === 'loading' ? 'active' : ''} d-inline-block`}
              onClick={() => loadProgrammation()}
              disabled={lookahead.loadStatus === 'loading'}
            >
              <Icon name="sync" fas fw spin={lookahead.loadStatus === 'loading'} />
              <span className="d-none d-sm-inline"> {t('LOOKAHEAD:RefreshButton')}</span>
            </button>
          <button className="btn btn-link btn-link-header"
            onClick={() => navigateToWeek(navigator.prev)}
            disabled={navigator.prev === ''}
          >
            <span className="icon-anterior"></span>
            <span className="d-none d-sm-inline"> {t('LOOKAHEAD:PreviousButton')}</span>
          </button>
          <button className="btn btn-link btn-link-header"
            onClick={(e) => navigateToWeek()}
          >
            <span className="icon-semana_actual"></span>
          </button>
          <button className="btn btn-link btn-link-header"
            onClick={(e) => navigateToWeek(navigator.next)}
            disabled={navigator.next === ''}
          >
            <span className="d-none d-sm-inline">{t('LOOKAHEAD:NextButton')} </span>
            <span className="icon-siguiente"></span>
          </button>
          <button className={`btn btn-link btn-link-header ${hasActiveFilters() ? 'active' : ''}`}
            onClick={() => setShowTaskFilters(true)}
          >
            <span className="icon-filtro"></span>
            <span className="d-none d-sm-inline"> {t('LOOKAHEAD:FilterButton')}</span>
          </button>
          <button className={`btn btn-link btn-link-header ${showSidebar ? 'active' : ''}`}
            ref={sidebarRefBtn}
            onClick={() => toggleSidebar()}
          >
            <span className="icon-configuracion"></span>
            <span className="d-none d-sm-inline"> {t('LOOKAHEAD:SettingsButton')}</span>
          </button>
        </div>
        <div className="ul-widget__head-nav">
        </div>
      </MainLayoutContentHeader>
      <MainLayoutContentBody>
        <Card className="table-card">
          {project._id && (
            <Gantt
              schema="lookahead"
              start={lookahead.start}
              end={lookahead.end}
              items={lookahead.ganttItems}
              columns={columns}
              nonWorkingDays={nonWorkingDays}
              dayWidth={mySettings?.ganttLookaheadColumnSize || 40}
              rowHeight={32}
              maxRowHeight={32}
              hideNonWorkingDays={mySettings?.ganttLookaheadHideNonWorkingDays || false}
              showVerticalBorders={mySettings?.ganttLookaheadShowVerticalBorders || false}
              divisorPosition={mySettings?.ganttLookaheadDivisorPosition}
              useCodeColors={mySettings?.useCodeColors || false}
              stretchUpToday={mySettings?.stretchUpToday || false}
              moveToScheduledDays={mySettings?.moveToScheduledDays || false}
              columnsHidden={mySettings?.ganttHideLookaheadFields || []}
              showSidebar={showSidebar}
              onSelectItem={handleEditItem}
              onToggleCollapse={handleToggleCollapse}
              onShowWorkflows={(groups: string[]) => handleShowWorkflows(groups)}
              onClickOutside={toggleSidebar}
              onUpdateSetting={handleUpdateSetting}
              sortEnabled={false}
              lineBaseTasks={false}
            />
          )}
        </Card>
      </MainLayoutContentBody>

      <TaskFilters
        show={showTaskFilters}
        type="lookahead"
        filters={filters}
        onChange={handleChangeFilters}
        onHide={() => setShowTaskFilters(false)}
      />

      <TaskEditor
        show={showTaskEditor}
        task={task}
        group={group}
        taskList={lookahead.result}
        nonWorkingDays={nonWorkingDays}
        project={project}
        projectMembers={TeamsHelper.getAllMembersFromProject(teams)}
        projectStartDate={project.startsAt}
        projectEndDate={project.endsAt}
        onHide={() => handleCloseTaskEditor()}
        onUpdated={handleTaskUpdated}
        onConfirmOrReject={handleConfirmedOrRejected}
        onDelete={(data: any) => handleTaskDeleted(data)}
        canConfirmOrReject={ability.can('confirm', 'task')}
        canDelete={ability.can('delete', 'task')}
        canUpdate={() => {

          const isPl4nner = ability.can('manage', 'all')
          const isAssistant = project.assistants?.includes(userData?.id)
          const isCollaborator = task?.responsibles.map((r: any) => r._id).includes(userData?.id)

          return isPl4nner || isAssistant || isCollaborator
        }}
      />
    </MainLayout>
  )
}

export default LookaheadPage
