import React, { useEffect, useState } from "react";
import moment from 'moment';

import { TChat, TChatMessage, TChatUser } from "../../../Types/Chat";

import { useAuth } from "../../../Hooks/UseAuth";
import { useChat } from "../../../Hooks/UseChat";

import UserAvatar from "../../UserAvatar/UserAvatar";
import OptionsDialog from "../../OptionsDialog/OptionsDialog";
import AddRestrictionDialog from "../../ChatCommands/AddRestrictionDialog/AddRestrictionDialog";

import "./ChatMessage.scss";
import useTranslation from "../../../Hooks/UseTranslation";

export interface XChatMessageProps {
  message: TChatMessage;
  chat: TChat;
  isMine: boolean;
}

const ChatMessage = ({ message, chat, isMine }: XChatMessageProps) => {

  const { t } = useTranslation();

  const { userData } = useAuth()

  const { readMessage, sendAutomaticMessage } = useChat();

  const myId = userData?.id

  const [ showProfileDialog, setShowProfileDialog ] = useState(false);
  const [ showAddRestrictionDialog, setShowAddRestrictionDialog ] = useState(false);

  const getOrientation = (): string => {

    return (isMine ? 'right' : 'left')
  }

  const handleSaved = (message: string) => {

    sendAutomaticMessage(message)

    setShowAddRestrictionDialog(false)
  }

  useEffect(() => {

    if (!myId) return

    const isViewedByMe = message.viewedBy.find((user: TChatUser) => user._id === myId);

    if (!isViewedByMe) readMessage(message)
  }, [ message ]);

  return (
    <>
      <div className={`chat-message chat-message-${getOrientation()}`}>
        {!message.isAutomatic && (
          <div className={`avatar avatar-${getOrientation()}`}>
            <UserAvatar user={message.sender} />
          </div>
        )}
        <div className={`message-container message-container-${getOrientation()}`}>
          <div className="message-header">
            <div>
              {message.sender.name} {message.sender.surname}
            </div>
            <div className="message-time">
              {moment(message.createdAt).format('HH:mm')}
              <button className="small-button" onClick={() => setShowProfileDialog(true)}>
                <i className="fas fa-fw fa-ellipsis-v" />
              </button>
            </div>
          </div>
          <div className="message-content">
            {message.content}
          </div>
        </div>
      </div>

      <OptionsDialog
        title={t('CHAT_MESSAGE:Title')}
        show={showProfileDialog}
        onHide={() => setShowProfileDialog(false)}
        options={[
          {
            icon: <span className="icon-restricciones_recurso"></span>,
            label: t('CHAT_MESSAGE:AddAsConstraint'),
            onClick: () => setShowAddRestrictionDialog(true)
          },
        ]}
      />

      <AddRestrictionDialog
        show={showAddRestrictionDialog}
        chatId={chat._id}
        messageId={message._id}
        projectId={chat.project?._id}
        content={message.content}
        onCancel={() => setShowAddRestrictionDialog(false)}
        onSaved={(message: string) => handleSaved(message)}
      />
    </>
  );
};

export default ChatMessage;




