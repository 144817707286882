
import React, { useEffect, useState } from "react";
import { Card, Col, Form, InputGroup, Pagination, Row, Table } from "react-bootstrap";
import moment from "moment";

import { archiveProject, getProjects, purgeTasks, restoreProject } from "../../../Services/AdminProjectsService";

import AdminLayout from "../../../Layouts/AdminLayout/AdminLayout";

import Icon from "../../../Components/Icon/Icon";

const AdminProjectsPage = () => {

  const [ projects, setProjects ] = useState<any[]>([])
  const [ pagination, setPagination ] = useState<any>({
    page: 1,
    limit: 10,
    total: 0,
  })
  const [ paginationItems, setPaginationItems ] = useState<any[]>([])
  const [ criteria, setCriteria ] = useState<any>('')

  const loadProjects = async () => {

    const { data, total }  = await getProjects(criteria, pagination.page - 1, pagination.limit)

    setProjects(data)
    setPagination({ ...pagination, total })
  }

  const handleArchiveProject = async (project_id: string) => {

    const { error } = await archiveProject(project_id)

    if (error) {

      console.log('error', error)
      return
    }

    loadProjects()
  }

  const handleRestoreProject = async (project_id: string) => {

    const { error } = await restoreProject(project_id)

    if (error) {

      console.log('error', error)
      return
    }

    loadProjects()
  }

  const handlePurgeTasks = async (project_id: string) => {

    const { error } = await purgeTasks(project_id)

    if (error) {

      console.log('error', error)
      return
    }

    loadProjects()
  }

  useEffect(() => {

    loadProjects()
  }, [ pagination.page ])

  useEffect(() => {

    const getPaginationItems = () => {

      const items = []

      const pages = Math.ceil(pagination.total / pagination.limit)

      for (let i = 1; i <= pages; i++) {
        items.push(
          <Pagination.Item key={i} active={i === pagination.page} onClick={() => setPagination({ ...pagination, page: i })}>
            {i}
          </Pagination.Item>,
        )
      }

      return setPaginationItems(items)
    }

    getPaginationItems()
  }, [ pagination.total, pagination.page ])

  return (
    <>
      <AdminLayout title="Proyectos" menu="projects" submenu="list">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between pb-2">
                  <Card.Title className="">Proyectos</Card.Title>
                  <div className="btn-toolbar">
                    <InputGroup>
                      <Form.Control
                        placeholder="Buscar..."
                        value={criteria}
                        onChange={(e: any) => setCriteria(e.target.value)}
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                            loadProjects();
                          }
                        }}
                      />
                      <InputGroup.Append>
                        <button className="btn btn-normal"
                          onClick={() => loadProjects()}
                        >
                          <i className="fa fa-fw fa-search"></i>
                        </button>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
                <div className="table-responsive">
                  <Table bordered hover>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ width: 40 }}>#</th>
                        <th scope="col">Nombres</th>
                        <th scope="col">Pl4nner</th>
                        <th scope="col" style={{ width: 200 }}>Tareas</th>
                        <th scope="col" style={{ width: 130 }}>Fec. Creado</th>
                        <th scope="col" style={{ width: 130 }}>Ult. Revisión</th>
                        <th scope="col" style={{ width: 130 }}>Días sin abrir</th>
                        <th scope="col" style={{ width: 260 }}>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects.map((project: any, i: number) => (
                        <tr key={project._id}>
                          <td>{((pagination.page - 1) * pagination.limit) + i + 1}</td>
                          <td>
                              <strong>{project.name}</strong>
                              <div>
                                <small>
                                  <i>{project.shortName}</i>
                                </small>
                              </div>
                          </td>
                          <td>
                            {project.user?.name} {project.user?.surname}
                          </td>
                          <td>
                            {project.tasks_not_listed > 0 && (
                              <>
                                <div>
                                  En lista: {project.tasks_listed}
                                </div>
                                <div>
                                  Fuera de lista: <span className="text-danger">{project.tasks_not_listed}</span>{' '}
                                  <button className="btn btn-normal"
                                    onClick={() => handlePurgeTasks(project._id)}
                                  >
                                    <Icon name="trash" fas fw />
                                  </button>
                                </div>
                                <div>
                                  Total: {project.tasks}
                                </div>
                              </>
                            )}
                            {project.tasks_not_listed === 0 && project.tasks_listed}
                          </td>
                          <td>
                            {moment(project.createdAt).format('DD/MM/YYYY')}
                          </td>
                          <td>
                            {project.lastViewed && moment(project.lastViewed).format('DD/MM/YYYY HH:mm a')}
                          </td>
                          <td>
                            {moment().diff(moment(project.lastViewed), 'days')}
                          </td>
                          <td>
                            {!project.archived && (
                              <button className="btn btn-normal"
                                onClick={() => handleArchiveProject(project._id)}
                              >
                                <Icon name="archive" fas fw /> Archivar
                              </button>
                            )}
                            {project.archived && (
                              <button className="btn btn-normal"
                                onClick={() => handleRestoreProject(project._id)}
                              >
                                <img
                                  width={20}
                                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADqklEQVR4nO1YS2gTURQd8YP4QVQsIioqIooLRRDEhYK6ceVCBu3cG0ubebc/m3vT+sOFURDBhbhWwaIr9342iiCIFHRRRRDBulAqVNSF1mKtP17aJC/TZDKZjGkKOfAgJPe9OefdefedG8uqo4466qijjjoKo8PuWNDutC+2pgsIZBuBXFDA/QQ8TCh/9VDIY/o7hXJZQWK7VWtwj8hmQr6TIVxqKJQHrZjcYtUCFHALIY8WIfpNoXwhlD+TfgP5oTBxdKrJn8knrF8VuaEc3q/f/0wcYs98gsReAuklkJ8eMeemhHwrcKNnZ5/FY7yp1Dw31r2BQPryRSSbq8M6QwK6VhLId4PEPSKaF3R+U1NqLqHczs4HHm5t4jVWtUDI14yHv2xpOb6w7DWI5hHIc2MTrlrVQBv2NOh3Pftgh3dVWHZ/Tqx13qpe1clWmUeVruc6vJVibKd2p2ZZ1YBCuZVLOyes6QLbtme2HO5coYCf5jLQtdOqZbjpasMXCfmVAv7tvYzaHVkXxXMU8HVj/V+E/IGQHyoQDlMgNGYolBMKecTPEsTt5JJKyZN9cpGv9QD56KJAeYuiXJ1sC9KVZ1AhD+gRZdVQ+Rko7J+AzwYjDyyeHejT1sC2U3OsKp01d/yidAnkjcmlZCaam7uWEfBXg3xvlOXNdeQAgYzpoT9bJZD2UKbLBfnoeyYI5JSRsn4imh0V+fSllXemeER/F0iEmQmQrqLBCvlx7oZNOkHJxTG5nkDe6qE/FyH/efK7zZ+DiHAdVoZ1uV9cAPBQJrCj6djyoAII5LSxQ6eDkQ8uov1Q9ypjzqCPAPmRCSzn0OoKUahaeMmbZTm/RLOvCM3FiB0tSoRQ3udO/NG1lQqYKLdZkgr4ihF3xSNuoCivWGJ1oAykfX2u5ePoBIzvsDeOjAz5CkChQGcg77Agf+iMdS6tRED6cDvJnszBLhQX98R4oVtTM5O+fbTulBTyOyMLT/TdEFZA2Lg88sB3jTlDJb2RvnXHDVVWxCdCSbmQ3KHNmx7e9jFKAbZtz5yoOpQuzUbF0n24FQQUkzZTRAFfNOKi7IlCQBv2NBDyC19DNx5/JhD57MNiso+AXxcXIZeiEEBO0ilBfCjwznuhfZDCxEECvqkbmYwTTTc1jd0b/1MGfulSqauNtg1h+4Gy8L8OcdUw7QTEQ9b3mhGgQt6wNShAyvI4NSOAQrrMmhEQ1uf79Q1TgnI7rVKd25QgbK9bU3DL/LchSvwDzfeDZ1meZRIAAAAASUVORK5CYII="
                                />{' '}
                                Restaurar
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="table-responsive">
                  <Pagination>
                    {paginationItems}
                  </Pagination>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </AdminLayout>
    </>
  )
}

export default AdminProjectsPage
