import React, { Fragment } from 'react';
import useTranslation from '../../../Hooks/UseTranslation';



type Props = {
    isNotRead: boolean,
    icon: any,
    data: any,
    onClick: any,
    timeDifference: string
    action: any
}

const TaskResponsableNotification = (props: Props) => {

    const { t, language } = useTranslation()

    const {
        who,
        task,
        project,
    } = props.data

    return (
        <div className={`${props.isNotRead ? 'dropdownnotification' : ''} dropdown-item d-flex`}
            onClick={props.onClick}
        >
            <div className="notification-icon" style={{ padding: '10px' }}>
                {props.icon}
            </div>
            <div className="notification-details flex-grow-1">
                <p className="m-0 d-flex align-items-center">
                    <span>
                        {t('NOTIFICATIONS_TASK_RESPONSABLE:Project')}{' '}
                        <strong>{props.data.project.name}</strong>
                    </span>
                    <span
                        className={`badge badge-pill badge-muted ml-1 mr-1`}
                    >
                        {props.data.status === 'read' ? null : (
                            <span className="badge badge-pill badge-primary ml-1 mr-1">
                                {t('NOTIFICATIONS_TASK_RESPONSABLE:Pending')}
                            </span>
                        )}
                    </span>
                    <span className="flex-grow-1"></span>
                    <span className=" text-muted ml-auto">
                        {language === 'es' && (
                            <Fragment>
                                {t('NOTIFICATIONS_TASK_RESPONSABLE:Ago')}{' '}
                                {props.timeDifference}
                            </Fragment>
                        )}
                        {language === 'en' && (
                            <Fragment>
                                {props.timeDifference}{' '}
                                {t('NOTIFICATIONS_TASK_RESPONSABLE:Ago')}
                            </Fragment>
                        )}
                    </span>
                </p>
                <p className=" text-muted m-0">
                    <span className='notification-content'>
                        {props.action === 'assigned' && (
                            <span>
                                {props.data.userFrom.name} {props.data.userFrom.surname}{' '}
                                {t('NOTIFICATIONS_TASK_RESPONSABLE:HasAssignedYouAsResponsibleOfTheTask')}{' '}
                                <strong style={{ textTransform: 'uppercase' }}>
                                    {props.data.task?.name}
                                </strong>
                            </span>

                        )}
                        {props.action === 'removed' && (
                            <span>
                                {t('NOTIFICATIONS_TASK_RESPONSABLE:YouHaveBeenRemovedAsResponsibleOfTheTask')}{' '}
                                <strong style={{ textTransform: 'uppercase' }}>
                                    {props.data.task?.name}
                                </strong>
                            </span>
                        )}
                        {props.action === 'not_more_responsible' && (
                            <span>
                                {who.length > 0 && (
                                    <>
                                        <strong style={{ textTransform: 'uppercase' }}>
                                            {who[ 0 ].name} {who[ 0 ].surname}
                                        </strong>{' '}
                                    </>
                                )}
                                {t('NOTIFICATIONS_TASK_RESPONSABLE:HasBeenCeasedToBeResponsibleOfTheTask')}{' '}
                                <strong style={{ textTransform: 'uppercase' }}>
                                    {task.name}
                                </strong>{' '}
                            </span>
                        )}
                        {t('NOTIFICATIONS_TASK_RESPONSABLE:InTheProject')}{' '}
                        <strong style={{ textTransform: 'uppercase' }}>
                            {project.name}
                        </strong>.
                    </span>
                </p>
            </div>
        </div>
    )
}

export default TaskResponsableNotification