import React from 'react'
import useTranslation from '../../../Hooks/UseTranslation'

type Props = {
    isNotRead: boolean,
    icon: any,
    data: any,
    onClick: any,
    timeDifference: string
}

const TaskAssignedDelayNotification = (props: Props) => {

    const { t } = useTranslation()

    const {
        task,
        project,
        who,
        type,
    } = props.data

    let content = (
        <span className='notification-content'>
            {t('NOTIFICATIONS_TASK_ASSIGNED_DELAY:YourAssignedTask')}{' '}
            <strong style={{ textTransform: 'uppercase' }}>{task.name}</strong>{' '}
            {t('NOTIFICATIONS_TASK_ASSIGNED_DELAY:InTheProject')}{' '}
            <strong>{project.shortName}</strong>{' '}
            {t('NOTIFICATIONS_TASK_ASSIGNED_DELAY:Is')}{' '}
            <strong>
                {t('NOTIFICATIONS_TASK_ASSIGNED_DELAY:Delayed')}
            </strong>
        </span>
    )

    if (type === 'task_assigned_delay_to_assistant') {

        content = (
            <span className='notification-content'>
                {t('NOTIFICATIONS_TASK_ASSIGNED_DELAY:TheTask')}{' '}
                <strong style={{ textTransform: 'uppercase' }}>
                    {props.data.task.name}
                </strong>{' '}
                {who.length > 0 && (
                    <>
                        {t('NOTIFICATIONS_TASK_ASSIGNED_DELAY:AssignedTo')}{' '}
                        <strong key={who._id} style={{ textTransform: 'uppercase' }}>
                            {who[ 0 ].name} {who[ 0 ].surname}{' '}
                        </strong>
                    </>
                )}
                {t('NOTIFICATIONS_TASK_ASSIGNED_DELAY:InTheProject')}{' '}
                <strong>{props.data.project.shortName}</strong>{' '}
                {t('NOTIFICATIONS_TASK_ASSIGNED_DELAY:Is')}{' '}
                <strong>
                    {t('NOTIFICATIONS_TASK_ASSIGNED_DELAY:Delayed')}
                </strong>
            </span>
        )
    }

    return (
        <div className={`${props.isNotRead ? 'dropdownnotification' : ''} dropdown-item d-flex`}
            onClick={props.onClick}
        >
            <div className="notification-icon" style={{ padding: '10px' }}>
                {props.icon}
            </div>
            <div className="notification-details flex-grow-1">
                <p className="m-0 d-flex align-items-center">
                    <span>{props.data.title}</span>
                    <span
                        className={`badge badge-pill badge-muted ml-1 mr-1`}
                    >
                        {props.data.status === 'read' ? null : (
                            <span className="badge badge-pill badge-primary ml-1 mr-1">Pendiente</span>
                        )}
                    </span>
                    <span className="flex-grow-1"></span>
                    <span className=" text-muted ml-auto">
                        Hace {props.timeDifference}
                    </span>
                </p>
                <p className=" text-muted m-0">{content}</p>
            </div>
        </div>
    )
}

export default TaskAssignedDelayNotification