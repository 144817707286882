
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Card, Nav, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import config from "../../config";
import navigation from "../../navigation";

import History from "../../Helpers/History";
import { getCnCWeekNavigator } from "../../Helpers/CncHelper";

import { useAuth } from "../../Hooks/UseAuth";
import { usePlan } from "../../Hooks/UsePlan";
import useTranslation from "../../Hooks/UseTranslation";
import { useProject } from "../../Hooks/UseProject";

import InterfaceActions from "../../Redux/Actions/InterfaceActions";
import ProjectCNCActions from "../../Redux/Actions/ProjectCNCActions";
import ProjectsActions from "../../Redux/Actions/ProjectsActions";
import { loadIndicatorsPPCTasksAction } from "../../Redux/Actions/IndicatorsPPCActions";
import { assignCNCAction } from "../../Redux/Actions/ResourcesCNCActions";

import UserAvatar from "../../Components/UserAvatar/UserAvatar";
import CncSelector from "../../Components/CncSelector/CncSelector";
import MainLayout from "../../Components/MainLayout/MainLayout";
import CncEditor from "../../Components/CncEditor/CncEditor";
import MainLayoutContentHeader from "../../Components/MainLayout/MainLayoutContentHeader";
import MainLayoutContentBody from "../../Components/MainLayout/MainLayoutContentBody";
import Toast from "../../Components/Toast/Toast";
import Icon from "../../Components/Icon/Icon";

const ProjectResourcesCNCPage = (props: any) => {

    const { t } = useTranslation()

    const dispatch = useDispatch()

    const [ cncEditor, setCncEditor ] = useState({
        show: false,
        row: {},
        saving: false,
    })

    const [ cncSelector, setCNCSelector ] = useState({
        show: false,
        twp: {
            _id: ''
        },
        twp_index: -1,
    })

    const [ showingFilters, setShowingFilters ] = useState(false)
    const [ tab, setTab ] = useState('list')

    const week = props.match.params.week

    const { userData } = useAuth()
    const { plan, showCantCreateCustomCNCsModal } = usePlan()
    const { project_id, project } = useProject()

    const projectLoadStatus = useSelector((state: any) => state.ProjectsReducer.loadRowStatus)

    const resourcesCNC = useSelector((state: any) => state.ResourcesCNCReducer)
    // TODO: eval passing this into a new resourcesCNCReducer file
    const projectCNC = useSelector((state: any) => state.ProjectCNCReducer)

    const navigator = useMemo(() => {

        if (!project._id) return {
          prev: '',
          next: '',
          week: 0,
        }

        return getCnCWeekNavigator(project.startsAt, project.realEnd, week)
    }, [ project, week ])

    const navigateToWeek = (week?: any) => {

        if (week) {

          History.push(`/project/${project_id}/resources/cnc/${week}`)
        } else {

          History.push(`/project/${project_id}/resources/cnc`)
        }
    }

    const [ menu, submenu ] = navigation.getNavigation(
        props.match.params._id,
        'resources',
        'cnc'
    )

    const loadProjectCNC = async () => {

        await dispatch(ProjectCNCActions.loadResult(project._id))
    }

    const loadNotCompleted = async () => {

        await dispatch(loadIndicatorsPPCTasksAction(project._id, navigator.week.toString()))
    }

    const getWeekNumber = () => {

        const weekStart = moment(project.startsAt)

        const lastWeek = moment(navigator.week, 'YYYYWW')

        return lastWeek.diff(weekStart, 'weeks') + 1
    }

    const getWeekRange = () => {

        const weekMoment = moment(navigator.week, 'YYYYWW')

        return weekMoment.startOf('week').format('DD/MM/YYYY') + ' - ' + weekMoment.endOf('week').format('DD/MM/YYYY')
    }

    const setTaskWeeklyProgress = (twp: any, twp_index: number) => {

        setCNCSelector({
            show: true,
            twp,
            twp_index,
        })
    }

    const saveCnc = async (cnc: any) => {

        await dispatch(InterfaceActions.showLoader())

        cnc.project = project._id

        setCncEditor({
            ...cncEditor,
            saving: true,
        })

        if (!cnc._id) {

            await dispatch(ProjectCNCActions.createRow(cnc))
        }

        setCncEditor({
            ...cncEditor,
            show: false,
            row: {},
            saving: false,
        })

        await loadProjectCNC()

        dispatch(InterfaceActions.hideLoader())
    }

    const handleAssignCNC = async (cnc: any) => {

        const assigned = await dispatch(assignCNCAction(cncSelector.twp._id, cnc._id))

        if (!assigned) {

            Toast.show(t('RESOURCES_CNC:AssignAlertTitle'), (
                <>
                  {t('RESOURCES_CNC:AssignAlertContent')}
                  <button className='btn btn-primary'
                    onClick={() => handleAssignCNC(cnc)}
                  >
                    <Icon name="sync" fas fw /> {' '}
                    {t('RESOURCES_CNC:AssignAlertRetryButton')}
                  </button>
                </>
            ), {
                type: 'default',
                position: 'bottom-right',
                autoClose: false,
                closeOnClick: false,
                closeButton: false,
                draggable: false,
            });
            return
        }

        Toast.close()
    }

    const updateRow = async (row: any, e: any) => {

        const data = {
            enabled: e.target.checked
        }

        dispatch(InterfaceActions.showLoader())

        await dispatch(ProjectCNCActions.updateRow(project._id, row._id, data))

        await loadProjectCNC()

        dispatch(InterfaceActions.hideLoader())
    }

    const handleCreateCustomCNC = () => {

        if (plan.cannot('create', 'CustomCNC')) {

            showCantCreateCustomCNCsModal(t('RESOURCES_CNC:CantCreateCustomCNCs'))
            return
        }

        setCncEditor({ ...cncEditor, show: true })
    }

    useEffect(() => {

        if (projectLoadStatus === 'loaded' && projectCNC.loadResultStatus === 'none') {

            loadProjectCNC()
        }
    }, [ projectLoadStatus, projectCNC.loadResultStatus ])

    useEffect(() => {

        if (projectLoadStatus !== 'loaded') return

        const {
            loadStatus,
            week,
        } = resourcesCNC.notCompleted

        if (loadStatus === 'none' && loadStatus !== 'loading' || week !== navigator.week.toString()) {

            loadNotCompleted()
        }
    }, [ projectLoadStatus, resourcesCNC.notCompleted.loadStatus, navigator.week ])

    useEffect(() => {

        const projectLoaded = projectLoadStatus == 'loaded'
        const projectCNCsLoaded = projectCNC.loadResultStatus === 'loaded'
        const notCompletedLoaded = resourcesCNC.notCompleted.loadStatus === 'loaded'

        if (!projectLoaded || !projectCNCsLoaded || !notCompletedLoaded) {

            dispatch(InterfaceActions.showLoader())
        } else {

            dispatch(InterfaceActions.hideLoader())
        }
    }, [ projectLoadStatus, projectCNC.loadResultStatus, resourcesCNC.notCompleted.loadStatus ])

    return (
        <MainLayout
            username={`${userData?.name} ${userData?.surname}`}
            project={project}
            menuBar={menu}
            subMenuBar={submenu}
            rightTitle={project.shortName}
            avatar={userData?.avatar ? `${config.apiHost}/${userData.avatar}` : ''}
        >
            <MainLayoutContentHeader>
                <div className="ul-widget__head-label flex-grow-1">
                    <h3 className="ul-widget__head-title">
                        {t('RESOURCES_CNC:Title')}
                        {!!navigator.week && (
                            <>
                                {' '}| <small style={{ fontSize: '70%' }}>
                                    {t('RESOURCES_CNC:TitleWeek')} {getWeekNumber()} - {getWeekRange()}
                                </small>
                            </>
                        )}
                    </h3>
                </div>
                <div className="ul-widget__head-toolbar">
                    <div className="text-right">
                        {tab !== 'config' && (
                            <>
                                <button className="btn btn-link btn-link-header"
                                    onClick={() => navigateToWeek(navigator.prev)}
                                    disabled={resourcesCNC.notCompleted.loadStatus === 'loading' || navigator.prev === ''}
                                >
                                    <i className="icon-anterior"></i>
                                    <span className="d-none d-sm-inline"> {t('RESOURCES_CNC:ToolbarPreviousButton')}</span>
                                </button>
                                <button className="btn btn-link btn-link-header"
                                    onClick={(e) => navigateToWeek()}
                                    disabled={resourcesCNC.notCompleted.loadStatus === 'loading'}
                                >
                                    <i className="icon-semana_actual"></i>
                                </button>
                                <button className="btn btn-link btn-link-header"
                                    onClick={(e) => navigateToWeek(navigator.next)}
                                    disabled={resourcesCNC.notCompleted.loadStatus === 'loading' || navigator.next === ''}
                                >
                                    <span className="d-none d-sm-inline">{t('RESOURCES_CNC:ToolbarNextButton')} </span>
                                    <i className="icon-siguiente"></i>
                                </button>
                            </>
                        )}
                        {tab === 'config' && (
                            <button className="btn btn-link btn-link-header"
                                onClick={() => handleCreateCustomCNC()}
                            >
                                <span className="icon-agregar"></span> {' '}
                                {t('RESOURCES_CNC:ToolbarAddCNCButton')}
                            </button>
                        )}
                        <button className="btn btn-link btn-link-header">
                            <span className="icon-direccionar"></span>
                            <span className="d-none d-sm-inline">{t('RESOURCES_CNC:ToolbarViewIndicatorsButton')} </span>
                        </button>

                        <button className={`btn btn-link btn-link-header ${showingFilters && 'active'}`}>

                            <span className="icon-filtro"></span>
                            <span className="d-none d-sm-inline">{t('RESOURCES_CNC:ToolbarFilterButton')} </span>
                        </button>
                    </div>
                </div>
                <div className="ul-widget__head-nav">
                    <Tab.Container>
                        <Nav as="ul" className="nav-tabs nav-tabs-line nav-tabs-bold">
                            <Nav.Item as="li">
                                <Nav.Link active={tab === 'list'} onClick={() => setTab('list')}>
                                    <span className="icon-tablero"></span>
                                    <span className="hide-on-mobile"> {t('RESOURCES_CNC:ToolbarNotCompletedTab')} </span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link active={tab === 'config'} onClick={() => setTab('config')}>
                                    <span className="icon-configuracion"></span>
                                    <span className="hide-on-mobile"> {t('RESOURCES_CNC:ToolbarSettingsTab')} </span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Tab.Container>
                </div>
            </MainLayoutContentHeader>
            <MainLayoutContentBody>
                <Tab.Content className="no-padding">
                    <Tab.Pane active={tab === 'list'}>
                        <Card className="table-card">
                            <div>
                                <div className="table-responsive">
                                    <table className="table card-table">
                                        <thead>
                                            <th className="text-center" style={{ width: '5%' }}>#</th>
                                            <th>
                                                {t('RESOURCES_CNC:NotCompletedTaskColumn')}
                                            </th>
                                            <th className="text-center" style={{ width: '30%' }}>
                                                {t('RESOURCES_CNC:NotCompletedCNCColumn')}
                                            </th>
                                            <th className="text-center" style={{ width: '15%' }}>
                                                {t('RESOURCES_CNC:NotCompletedTypeColumn')}
                                            </th>
                                            <th className="text-center" style={{ width: '15%' }}>
                                                {t('RESOURCES_CNC:NotCompletedResponsibleColumn')}
                                            </th>
                                        </thead>
                                        <tbody>
                                            {resourcesCNC.notCompleted.result.map((taskWeeklyProgress: any, i: number) => (
                                                <tr key={i}>
                                                    <td className="text-center"></td>
                                                    <td>{taskWeeklyProgress.task.name}</td>
                                                    <td className="text-center">
                                                        {taskWeeklyProgress.cnc && taskWeeklyProgress.cnc.name}
                                                        {!taskWeeklyProgress.cnc && (
                                                            <>
                                                                {resourcesCNC.assigningList.includes(taskWeeklyProgress._id) ? (
                                                                    <>
                                                                        <div className="spinner spinner-primary" style={{ width: 16, height: 16 }}></div> {' '}
                                                                        {t('RESOURCES_CNC:NotCompletedAssigning')}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button className="btn btn-link"
                                                                            onClick={() => setTaskWeeklyProgress(taskWeeklyProgress, i)}
                                                                        >
                                                                            <Icon name="list-alt" far fw /> {' '}
                                                                            {t('RESOURCES_CNC:NotCompletedSelectButton')}
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {taskWeeklyProgress.cnc && (
                                                            <>
                                                                {taskWeeklyProgress.cnc.type === "obra_construccion" && t('RESOURCES_CNC:ProjectConstructionCNCType')}
                                                                {taskWeeklyProgress.cnc.type === "diseno_oficina_tecnica_de_obra" && t('RESOURCES_CNC:DesignOTOCNCType')}
                                                                {taskWeeklyProgress.cnc.type === "administracion" && t('RESOURCES_CNC:AdministrationCNCType')}
                                                                {taskWeeklyProgress.cnc.type === "logistica" && t('RESOURCES_CNC:LogisticsCNCType')}
                                                                {taskWeeklyProgress.cnc.type === "condiciones_externas" && t('RESOURCES_CNC:ExternalConditionsCNCType')}
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {taskWeeklyProgress.task.responsibles.map((responsable: any, i: number) => (
                                                            <UserAvatar key={i} user={responsable} />
                                                        ))}
                                                    </td>
                                                </tr>
                                            ))}
                                            {resourcesCNC.notCompleted.loadStatus !== 'loaded' && (
                                                <tr>
                                                    <td colSpan={5}>
                                                        {t('RESOURCES_CNC:LoadingNotCompleted')}
                                                    </td>
                                                </tr>
                                            )}
                                            {resourcesCNC.notCompleted.loadStatus === 'loaded' && resourcesCNC.notCompleted.result.length === 0 && (
                                                <tr>
                                                    <td colSpan={5}>
                                                        {t('RESOURCES_CNC:NotCompletedEmpty')}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Card>
                    </Tab.Pane>
                    <Tab.Pane active={tab === 'config'}>
                        <Card className="table-card">
                            <div>
                                <div className="table-responsive">
                                    <table className="table card-table">
                                        <thead>
                                            <th>
                                                {t('RESOURCES_CNC:SettingsCodeColumn')}
                                            </th>
                                            <th>
                                                {t('RESOURCES_CNC:SettingsDescriptionColumn')}
                                            </th>
                                            <th>
                                                {t('RESOURCES_CNC:SettingsTypeColumn')}
                                            </th>
                                            <th>
                                                {t('RESOURCES_CNC:SettingsStatusColumn')}
                                            </th>
                                        </thead>
                                        <tbody>
                                            {projectCNC.result.map((projectCnc: any, i: number) => (
                                                <tr key={`${i}-${projectCnc._id}`}>
                                                    <td>{projectCnc.cnc.code}</td>
                                                    <td>{projectCnc.cnc.name}</td>
                                                    <td>
                                                        {projectCnc.cnc.type === "obra_construccion" && t('RESOURCES_CNC:ProjectConstructionCNCType')}
                                                        {projectCnc.cnc.type === "diseno_oficina_tecnica_de_obra" && t('RESOURCES_CNC:DesignOTOCNCType')}
                                                        {projectCnc.cnc.type === "administracion" && t('RESOURCES_CNC:AdministrationCNCType')}
                                                        {projectCnc.cnc.type === "logistica" && t('RESOURCES_CNC:LogisticsCNCType')}
                                                        {projectCnc.cnc.type === "condiciones_externas" && t('RESOURCES_CNC:ExternalConditionsCNCType')}
                                                    </td>
                                                    <td>
                                                        <label className="switch pr-5 switch-primary mr-3">
                                                            <span>
                                                                {projectCnc.enabled ? t('RESOURCES_CNC:SettingsStatusActive') : t('RESOURCES_CNC:SettingsStatusInactive')}
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                checked={projectCnc.enabled}
                                                                onChange={(e: any) => updateRow(projectCnc, e)}
                                                            />
                                                            <span className="slider"></span>
                                                        </label>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Card>
                    </Tab.Pane>
                </Tab.Content>
            </MainLayoutContentBody>

            {cncEditor.show && (
                <CncEditor
                    show={cncEditor.show}
                    row={cncEditor.row}
                    result={projectCNC.result}
                    saving={cncEditor.saving}
                    onHide={() => setCncEditor({ ...cncEditor, show: false })}
                    onSave={(cnc: any) => saveCnc(cnc)}
                />
            )}

            {cncSelector.show && (
                <CncSelector
                    show={cncSelector.show}
                    onHide={() => setCNCSelector({ ...cncSelector, show: false })}
                    onSelect={(cnc: any) => handleAssignCNC(cnc)}
                />
            )}
        </MainLayout>
    )
}

export default ProjectResourcesCNCPage

