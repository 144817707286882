import React, { Fragment } from 'react';
import useTranslation from '../../../Hooks/UseTranslation';

type Props = {
    isNotRead: boolean,
    icon: any,
    data: any,
    onClick: any,
    timeDifference: string
}

const TeamMemberRemovedNotification = (props: Props) => {

    const { t, language } = useTranslation()

    if (!props.data.team) {

        return null
    }

    return (
        <div className={`${props.isNotRead ? 'dropdownnotification' : ''} dropdown-item d-flex`}
            onClick={props.onClick}
        >
            <div className="notification-icon" style={{ padding: '10px' }}>
                {props.icon}
            </div>
            <div className="notification-details flex-grow-1">
                <p className="m-0 d-flex align-items-center">
                    <span>
                        {t('NOTIFICATIONS_TEAMMEMBER_REMOVED:Project')}{' '}
                        {props.data.project.name}
                    </span>
                    <span
                        className={`badge badge-pill badge-muted ml-1 mr-1`}
                    >
                        {props.data.status === 'read' ? null : (
                            <span className="badge badge-pill badge-primary ml-1 mr-1">
                                {t('NOTIFICATIONS_TEAMMEMBER_REMOVED:Pending')}
                            </span>
                        )}
                    </span>
                    <span className="flex-grow-1"></span>
                    <span className="text-muted ml-auto">
                        {language === 'es' && (
                            <Fragment>
                                {t('NOTIFICATIONS_TEAMMEMBER_REMOVED:Ago')}{' '}
                                {props.timeDifference}
                            </Fragment>
                        )}
                        {language === 'en' && (
                            <Fragment>
                                {props.timeDifference}{' '}
                                {t('NOTIFICATIONS_TEAMMEMBER_REMOVED:Ago')}
                            </Fragment>
                        )}
                    </span>
                </p>
                <p className=" text-muted m-0">
                    <span className='notification-content'>
                        {props.data.userTo._id === props.data.team_member._id && (
                            <span>
                                <strong>
                                    {props.data.userFrom.name} {props.data.userFrom.surname}
                                </strong>{' '}
                                {t('NOTIFICATIONS_TEAMMEMBER_REMOVED:HasRemovedYouFromTheProject')}{' '}
                                <strong style={{ textTransform: 'uppercase' }}>
                                    {props.data.team.name}
                                </strong>
                            </span>
                        )}
                        {props.data.userTo._id !== props.data.team_member._id && (
                            <span>
                                <strong>
                                    {props.data.userFrom.name} {props.data.userFrom.surname}{' '}
                                </strong>{' '}
                                {t('NOTIFICATIONS_TEAMMEMBER_REMOVED:HasRemovedTo')}{' '}
                                <strong>
                                    {props.data.team_member.name} {props.data.team_member.surname}
                                </strong>{' '}
                                {t('NOTIFICATIONS_TEAMMEMBER_REMOVED:FromTheProject')}{' '}
                                <strong style={{ textTransform: 'uppercase' }}>
                                    {props.data.team.name}
                                </strong>
                            </span>
                        )}
                    </span>
                </p>
            </div>
        </div>
    )
}

export default TeamMemberRemovedNotification