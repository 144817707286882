
import React, { createContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import ProjectsActions from '../Redux/Actions/ProjectsActions';

interface ProjectProps {
  project_id: string ;
  project: any;
}

const ProjectContext = createContext<ProjectProps | undefined>(undefined)

export const ProjectProvider = ({ children }: { children: React.ReactNode }) => {

  const dispatch = useDispatch()

  const pathname = window.location.pathname;
  const project_id = pathname.split('/')[2]
  const isProjectRoute = pathname.split('/')[1] === 'project'

  const project = useSelector((state: any) => state.ProjectsReducer.rowData)

  const value: ProjectProps = {
    project_id,
    project,
  }

  useEffect(() => {

    const loadProject = async () => {

      await dispatch(ProjectsActions.getRowAction(project_id))
    }

    if (project_id && isProjectRoute) {

      loadProject()
    }
    return () => {
      dispatch(ProjectsActions.clearProjectAction())
    }
  }, [ project_id, isProjectRoute ])

  return (
    <ProjectContext.Provider value={value}>
      {children}
    </ProjectContext.Provider>
  )
}

export const useProject = (): ProjectProps => {

  const context = React.useContext(ProjectContext)

  if (context === undefined) {
    throw new Error('useProject must be used within a ProjectProvider')
  }

  return context
}
