import React, { Fragment } from 'react';
import useTranslation from '../../../Hooks/UseTranslation';

type Props = {
    isNotRead: boolean,
    icon: any,
    data: any,
    onClick: any,
    timeDifference: string
}

const InviteNotification = (props: Props) => {

    const { t, language } = useTranslation()

    if (!props.data.team) {

        return null
    }

    return (
        <div
            className={`${props.isNotRead ? 'dropdownnotification' : ''} dropdown-item d-flex`}
            onClick={props.onClick}
        >
            <div className="notification-icon" style={{ padding: '10px' }}>
                {props.icon}
            </div>
            <div className="notification-details flex-grow-1">
                <p className="m-0 d-flex align-items-center">
                    <span>
                        {t('NOTIFICATIONS_INVITE:JoinTheTeam')}
                    </span>
                    {props.data.status === 'read' ? null : (
                        <span className="badge badge-pill badge-primary ml-1 mr-1">
                            {t('NOTIFICATIONS_INVITE:Pending')}
                        </span>
                    )}
                    <span className="flex-grow-1"></span>
                    <span className=" text-muted ml-auto">
                        {language === 'es' && (
                            <Fragment>
                                {t('NOTIFICATIONS_INVITE:Ago')}{' '}
                                {props.timeDifference}
                            </Fragment>
                        )}
                        {language === 'en' && (
                            <Fragment>
                                {props.timeDifference}{' '}
                                {t('NOTIFICATIONS_INVITE:Ago')}
                            </Fragment>
                        )}
                    </span>
                </p>
                <p className=" text-muted m-0">
                    <span className='notification-content'>
                        <strong>
                            {props.data.userFrom.name} {props.data.userFrom.surname}
                        </strong>{' '}
                        {t('NOTIFICATIONS_INVITE:HasInvitedYouToTheTeam')}{' '}
                        <strong>{props.data.team.name}</strong>{' '}
                        {t('NOTIFICATIONS_INVITE:OfTheProject')}{' '}
                        <strong>{props.data.project.shortName}</strong>
                    </span>
                </p>
            </div>
        </div>
    )
}

export default InviteNotification