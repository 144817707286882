
import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import { getChatTasksFromList, getChatTasksTreeOptions } from "../../../Helpers/ChatHelper";

import { executeCommand, getChatProjectTasks } from "../../../Services/ChatService";

import SelectFormControl from "../../SelectFormControl/SelectFormControl";
import InputFormControl from "../../InputFormControl/InputFormControl";
import useTranslation from "../../../Hooks/UseTranslation";

interface AddRestrictionDialogProps {
  show: boolean;
  chatId: string;
  messageId: string;
  projectId: string;
  content: string
  onCancel: () => void;
  onSaved: (message: string) => void;
}

const AddRestrictionDialog = (props: AddRestrictionDialogProps) => {

  const {
    chatId,
    messageId,
    projectId,
    content,
  } = props

  const { t } = useTranslation();

  const dispatch = useDispatch()

  const { projectsTree } = useSelector((state: any) => state.ChatReducer)
  const macro = useSelector((state: any) => state.MacroReducer)

  const [ editable, setEditable ] = useState(true)
  const [ loadingTasks, setLoadingTasks ] = useState(false)
  const [ project, setProject ] = useState('')
  const [ task, setTask ] = useState('')
  const [ description, setDescription ] = useState('')
  const [ tasksOptions, setTasksOptions ] = useState<any[]>([])

  const [ saving, setSaving ] = useState(false)
  const [ formCompleted, setFormCompleted ] = useState(false);


  const handleSubmit = async () => {

    if (!task || !description) return

    setSaving(true)

    const params = [
      {
        key: 'task_id',
        value: task
      },
      {
        key: 'content',
        value: description
      }
    ]

    const { data, error } = await executeCommand(chatId, messageId, 'add-restriction', params)

    setSaving(false)

    props.onSaved(data.message)
  }

  useEffect(() => {

    setProject(projectId)
    setDescription(content)
  }, [ projectId, content ])

  useEffect(() => {

    const getTaskListOptions = async () => {

      setLoadingTasks(true)

      const { data: { tasks, taskList }} = await getChatProjectTasks(project)

      const list = getChatTasksFromList(taskList.list, tasks)

      const options = getChatTasksTreeOptions(list)

      setTasksOptions(options)

      setLoadingTasks(false)
    }

    if (project) getTaskListOptions()
  }, [ project ])

  useEffect(() => {

    setProject(projectId || '')
    setTask('')
    setDescription(content)
  }, [ props.show ])

  useEffect(() => {

    if (project && task && description) {
      setFormCompleted(true);
    } else {
      setFormCompleted(false);
    }
  }, [ project, task, description ]);

  return (
    <Modal
      show={props.show}
      onHide={props.onCancel}
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header>
        <Modal.Title>
          {t('ADD_RESTRICTION_DIALOG:Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="form-group">
            <label htmlFor="">
              {t('ADD_RESTRICTION_DIALOG:InputProjectLabel')}
            </label>
            <SelectFormControl
              value={project}
              onChange={(value: string) => setProject(value)}
            // disabled={!editable}
            >
              <option value="">
                {t('ADD_RESTRICTION_DIALOG:InputProjecPlaceholder')}
              </option>
              {projectsTree.map((project: any) => (
                <option key={project._id} value={project._id}>{project.name}</option>
              ))}
            </SelectFormControl>
          </div>
          <div className="form-group">
            <label htmlFor="">
              {t('ADD_RESTRICTION_DIALOG:InputTaskLabel')}
            </label>
            <SelectFormControl
              value={task}
              onChange={(value: string) => setTask(value)}
              disabled={loadingTasks}
            >
              <option value="">
                {loadingTasks ?
                  t('ADD_RESTRICTION_DIALOG:InputTaskLoadingPlaceholder') :
                  t('ADD_RESTRICTION_DIALOG:InputTaskSelectPlaceholder')}
              </option>
              {tasksOptions.map((option: any, i: number) => (
                <Fragment key={`${uuid()}-${i}`}>
                  {option.isGroup && (
                    <optgroup key={option.value} label={option.label}></optgroup>
                  )}
                  {!option.isGroup && (
                    <option key={i} value={option.value}>{option.label}</option>
                  )}
                </Fragment>
              ))}
              {/* {rootGroups.map((group: any) => (
                <option key={group._id} value={group._id}>{group.name}</option>
              ))} */}
            </SelectFormControl>
          </div>
          <div className="form-group">
            <label htmlFor="">
              {t('ADD_RESTRICTION_DIALOG:InputNameLabel')}
            </label>
            <InputFormControl
              value={description}
              onChange={setDescription}
              // onEnter={() => saveGroup()}
              // onEscape={() => resetForm()}
              readOnly={!editable}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-secondary" onClick={props.onCancel}>
          <span className="icon-cancelar"></span>{' '}
          {t('ADD_RESTRICTION_DIALOG:CancelButton')}
        </Button>
        <Button className="btn-primary" onClick={() => handleSubmit()} disabled={!formCompleted || saving}>
          <span className="icon-guardar" ></span>{' '}
          {saving ?
            t('ADD_RESTRICTION_DIALOG:SaveButtonSaving') :
            t('ADD_RESTRICTION_DIALOG:SaveButton')
          }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddRestrictionDialog;
