
import config from "../config"

import { del, get } from "./ApiService"


export const getProjects = async (criteria: string, page: number, limit: number) => {

  const url = `${config.apiHost}/admin/projects?criteria=${encodeURIComponent(criteria)}&page=${page}&limit=${limit}`

  const { data, error } = await get(url, 'ADMIN_PROJECTS')

  if (error) return { error }

  const { rows, total } = data

  return { data: rows, total }
}

export const archiveProject = async (project_id: string) => {

  const url = `${config.apiHost}/admin/projects/${project_id}/archive`

  const { error } = await get(url, 'ADMIN_PROJECTS')

  if (error) return { error }

  return {
    data: true
  }
}

export const restoreProject = async (project_id: string) => {

  const url = `${config.apiHost}/admin/projects/${project_id}/unarchive`

  const { error } = await get(url, 'ADMIN_PROJECTS')

  if (error) return { error }

  return {
    data: true
  }
}

export const purgeTasks = async (project_id: string) => {

  const url = `${config.apiHost}/admin/projects/${project_id}/purge-tasks`

  const { error } = await del(url, 'ADMIN_PROJECTS')

  if (error) return { error }

  return {}
}
