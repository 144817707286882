
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import moment from 'moment';

import { GanttColumn, ProgrammationSchema } from './types';

import { getGanttDaysAndWeeks, getItemDaysBefore, getItemWidth, getTableWidth } from './GanttHelper';
import { getYearWeekFromDate } from '../../Helpers/DateFunctions';

import Divisor from './Divisor';
import GanttChartHeader from './GanttChartHeader';
import GanttTableBody from './GanttTableBody';
import GanttChartBody from './GanttChartBody';
import Sidebar from './Sidebar';

import './Scss/_Gantt.scss'

type Props = {
  schema: ProgrammationSchema;
  start: string;
  end: string;
  items: any[];
  columns: GanttColumn[];
  nonWorkingDays: string[];
  dayWidth: number;
  rowHeight: number;
  hideNonWorkingDays: boolean;
  showVerticalBorders: boolean;
  divisorPosition: number;
  useCodeColors: boolean;
  stretchUpToday: boolean;
  moveToScheduledDays: boolean;
  columnsHidden: string[];
  showSidebar: boolean;
  sortEnabled: boolean;
  lineBaseTasks: boolean;
  maxDayWidth?: number;
  maxRowHeight?: number;

  onSelectItem: (item_id: string, editMode?: boolean) => void;
  onToggleCollapse: (group_id: string) => void;
  onShowWorkflows: (groups: string[]) => void;
  onClickOutside: (target: any) => void;
  onUpdateSetting: (key: string, value: any) => void;
  onUpdateOrder?: (action: any) => void;
  onItemsRightClick?: (selectedItems: string[]) => void;
  onGanttRightClick?: () => void;
}

const Gantt = (props: Props) => {

  const frameRef: any = useRef()
  const tableHeaderRef: any = useRef()
  const tableBodyRef: any = useRef()
  const chartHeaderRef: any = useRef()
  const chartBodyRef: any = useRef()
  const sidebarRef: any = useRef()

  const scrollTopRef = useRef(0)

  const ganttHoverContainerRef: any = useRef(null)
  const ganttHoverItemRef: any = useRef(null)

  const shiftPressedRef = useRef(false);

  const interfaceHeights = useSelector((state: any) => state.InterfaceReducer.heights)

  const [ divisorPosition, setDivisorPosition ] = useState(props.divisorPosition)

  const [ scrollTop, setScrollTop ] = useState(0)

  const [ selectedItems, setSelectedItems ] = useState<any[]>([])

  const [ frameLeft, setFrameLeft ] = useState(0)
  const [ minTableWidthPercent, setMinTableWidthPercent ] = useState(30)

  const [ itemHovering, setItemHovering ] = useState<number | undefined>()

  const columns = useMemo(() => {

    return props.columns.filter((column: GanttColumn) => {

      return !props.columnsHidden.includes(column.field)
    })
  }, [ props.columnsHidden ])

  const frameheight = useMemo(() => {

    const contentBodyMargin = 12

    const height = window.innerHeight -
      interfaceHeights.headerHeight -
      interfaceHeights.submenuHeight -
      interfaceHeights.contentHeaderHeight -
      contentBodyMargin -
      interfaceHeights.contentWrapPaddingTop -
      interfaceHeights.contentWrapPaddingBottom -
      interfaceHeights.footerHeight

    return height
  }, [ interfaceHeights ])

  const frameWidth = useMemo(() => {

    if (frameRef && frameRef.current) {

      return frameRef.current.clientWidth
    }

    return 0
  }, [ frameRef, props.columns ])

  const [ tableWidth, setTableWidth ] = useState(0)

  const [ visibleItems, setVisibleItems ] = useState<any[]>([])

  const [ days, weeks ] = useMemo(() => {

    return getGanttDaysAndWeeks(props.start, props.end, props.nonWorkingDays, props.hideNonWorkingDays)
  }, [ props.start, props.end, props.nonWorkingDays, props.hideNonWorkingDays ])

  const { contentHeight, contentLength } = useMemo(() => {

    // sum all the heights of the items
    let totalHeight = 0
    props.items.forEach((item: any) => {

      const isExpandedGroup = item.schema === 'group' && !item.isCollapsed
      const isMeeting = item.schema === 'task' && item.type === 'reunion'
      const isMilestone = item.schema === 'task' && item.type === 'hito'

      if (isExpandedGroup || isMeeting || isMilestone) {

        totalHeight += 32
      } else {

        totalHeight += props.rowHeight
      }
    })

    const contentLength = (frameheight - 64) / props.rowHeight | 0

    return { contentHeight: totalHeight, contentLength }
  }, [ props.items ])

  const scrollGanttToLeft = (x: number) => {

    const scrollDiv = chartBodyRef.current.scrollLeft
    const scrollWidth = chartBodyRef.current.offsetWidth
    const scrollLimit = scrollDiv + scrollWidth

    const itemPosition = x * props.dayWidth

    if ((scrollLimit <= itemPosition) || (scrollDiv > itemPosition)) {

      chartBodyRef.current.scrollLeft = (x ? x - 1 : x) * props.dayWidth
      chartHeaderRef.current.scrollLeft = (x ? x - 1 : x) * props.dayWidth
    }
  }

  const handleItemClick = (item: any, isItRight: boolean) => {

    scrollGanttToLeft(item.left / props.dayWidth)

    const currentSelectedIsGroup = selectedItems[0]?.schema === 'group'
    const itemIsSelected = selectedItems.find((selectedItem: any) => selectedItem._id === item._id && selectedItem.displayMode === item.displayMode) || false
    const itemIsGroup = item.schema === 'group'
    const isShiftPressed = shiftPressedRef.current

    if (!isShiftPressed) {

      const newSelectedItems: any[] = [ item ]

      if (!itemIsSelected) {

        setSelectedItems(newSelectedItems)
        props.onSelectItem(item._id, false)
      } else {

        if (selectedItems.length > 1 && !isItRight) {

          setSelectedItems(newSelectedItems) // sets as the only selected item
          props.onSelectItem(item._id, false)
        } else if (selectedItems.length === 1 && !isItRight) {

          props.onSelectItem(item._id, true)
        }
      }

      if (isItRight) {
        props.onItemsRightClick && props.onItemsRightClick(!itemIsSelected ? [ item ] : selectedItems)
      }
    } else if (isShiftPressed && !isItRight) {

      const items = [ ...selectedItems ]

      if (itemIsGroup || currentSelectedIsGroup) {

        // replace all selected items with the new one
        setSelectedItems([ item ])
      } else {

        if (itemIsSelected) {

          const index = items.findIndex((selectedItem: any) => selectedItem._id === item._id)

          items.splice(index, 1)
        } else {

          items.push(item)
        }

        setSelectedItems(items)
      }
    }
  }

  const handleChangeScrollTop = (scrollTop: number) => {

    const topPosition = scrollTop / props.rowHeight | 0

    if (scrollTopRef.current !== topPosition) {

      setScrollTop(topPosition)
    }
  }

  const maxTableWidthPercent = 70

  let tableScrollLeft = 0
  let chartScrollLeft = 0

  const handleShowContextMenu = (e: any) => {
    e.stopPropagation()
    e.preventDefault()

    props.onGanttRightClick && props.onGanttRightClick()
  }

  const onTableBodyScroll = () => {

    const left = tableBodyRef.current.scrollLeft
    const top = tableBodyRef.current.scrollTop

    if (tableScrollLeft !== left) {

      tableScrollLeft = left

      tableHeaderRef.current.scrollLeft = left
    }


    scrollTopRef.current = top
    handleChangeScrollTop(top)

    //TODO: Scroll chart
    chartBodyRef.current.scrollTop = top
    ganttHoverContainerRef.current.scrollTop = top
  }

  const onChartBodyScroll = () => {

    const left = chartBodyRef.current.scrollLeft
    const top = chartBodyRef.current.scrollTop

    if (chartScrollLeft !== left) {

      chartScrollLeft = left

      chartHeaderRef.current.scrollLeft = left
    }


    scrollTopRef.current = top
    handleChangeScrollTop(top)

    tableBodyRef.current.scrollTop = top
    ganttHoverContainerRef.current.scrollTop = top
  }

  const showWorkflows = () => {

    props.onShowWorkflows(props.items.filter((item: any) => item.schema === 'group' && item.hasChildGroup === false).map((item: any) => item._id))
  }

  const handleToggleColumn = (columnName: string, toggle: boolean) => {

    const columnsHidden = [ ...props.columnsHidden ]

    if (!toggle) {

      columnsHidden.push(columnName)
    } else {

      const index = columnsHidden.indexOf(columnName)

      columnsHidden.splice(index, 1)
    }

    props.onUpdateSetting('columnsHidden', columnsHidden)
  }

  const handleItemMouseOver = (itemIndex: number) => {

    if (ganttHoverItemRef.current) {

      const item = visibleItems[itemIndex]

      const height = (item.schema === 'group' && !item.isCollapsed) ? 32 : item.height

      ganttHoverItemRef.current.style.top = `${visibleItems[itemIndex].top}px`
      ganttHoverItemRef.current.style.height = `${height}px`
    }
  }

  useEffect(() => {

    const tableWidth = getTableWidth(columns)

    const tableWidthPercent = tableWidth * 100 / frameWidth

    if (tableWidthPercent < divisorPosition) {

      setDivisorPosition(tableWidthPercent)
      setMinTableWidthPercent(tableWidthPercent)

      props.onUpdateSetting('divisorPosition', tableWidthPercent)
    }

    setTableWidth(tableWidth)
  }, [ columns, props.columnsHidden ])

  useEffect(() => {

    const handleClickOutside = (e: any) => {

      if (sidebarRef.current) {

        const clickOnSidebar = sidebarRef.current.contains(e.target)

        if (!clickOnSidebar && props.showSidebar && props.onClickOutside) {

          props.onClickOutside(e.target)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ sidebarRef, props.showSidebar ])

  useEffect(() => {

    setDivisorPosition(props.divisorPosition)
  }, [ props.divisorPosition ])

  useEffect(() => {

    const topPosition = scrollTop - 10
    const listSize = contentLength + 20

    const getItemTop = (i: number) => {

      let top = 0

      for (let j = 0; j < i; j++) {

        const isExpandedGroup = props.items[j].schema === 'group' && !props.items[j].isCollapsed

        const isMeeting = props.items[j].schema === 'task' && props.items[j].type === 'reunion'
        const isMilestone = props.items[j].schema === 'task' && props.items[j].type === 'hito'

        if (isExpandedGroup || isMeeting || isMilestone) {

          top += 32
        } else {

          top += props.rowHeight
        }
      }

      return top
    }

    /**
     * TODO: en vez de recorrer todos los items, recorre una cantidad de items que quepan en el
     * viewport y coge un item de la lista
     */

    if (!props.items.length) {

      setVisibleItems([])

      return
    }

    const visibleItems: any[] = []

    for (let i = 0; i < listSize; i++) {

      const index = topPosition + i

      if (index < 0) continue

      const item = props.items[index]

      if (!item) continue

      const daysBeforeStart = getItemDaysBefore(props.start, item.barStart, props.nonWorkingDays, props.hideNonWorkingDays)
      const left = daysBeforeStart * props.dayWidth

      const daysBeforeStartProgrammed = getItemDaysBefore(props.start, item.startsAt, props.nonWorkingDays, props.hideNonWorkingDays)

      const leftProgrammed = daysBeforeStartProgrammed * props.dayWidth

      let barStart = item.barStart
      let barEnd = item.barEnd

      const top = getItemTop(index)

      if (item.schema === "task") {

        if (props.schema === 'lookahead' || props.schema === 'weekly') {

          barStart = moment(barStart).isBefore(props.start, 'day') ? props.start : barStart
          barEnd = moment(barEnd).isAfter(props.end, 'day') ? props.end : barEnd
        }

        const daysSize = getItemWidth(barStart, barEnd, props.nonWorkingDays, props.hideNonWorkingDays)

        const daysSizeProgrammed = getItemWidth(item.startsAt, item.endsAt, props.nonWorkingDays, props.hideNonWorkingDays)

        // TODO: recalculate item.executePercent
        if (props.schema === 'lookahead') {

          const periodDays = getItemWidth(barStart, barEnd, props.nonWorkingDays, true)

          item.periodDays = periodDays
          item.executePercent = item.periodProgressExpected || (periodDays * 100 / item.executeDays).toFixed(0)
        }

        const isMilestone = item.type === 'hito'
        const isMeeting = item.type === 'reunion'

        const height = (isMilestone || isMeeting) ? 32 : props.rowHeight

        visibleItems.push({
          ...item,
          left,
          leftProgrammed,
          top,
          width: daysSize * props.dayWidth,
          height,
          widthProgrammed: daysSizeProgrammed * props.dayWidth,
        })

        continue
      }

      let daysSize = 0

      if (barStart && barEnd) {

        daysSize = getItemWidth(barStart, barEnd, props.nonWorkingDays, props.hideNonWorkingDays)
      }

      item.bars?.forEach((bar: any) => {

        bar.left = getItemDaysBefore(props.start, bar.barStart, props.nonWorkingDays, props.hideNonWorkingDays) * props.dayWidth
        bar.width = getItemWidth(bar.barStart, bar.barEnd, props.nonWorkingDays, props.hideNonWorkingDays) * props.dayWidth
      })

      visibleItems.push({
        ...item,
        left,
        top,
        height: props.rowHeight,
        width: daysSize * props.dayWidth,
      })
    }

    setVisibleItems(visibleItems)
  }, [ scrollTop, days, props.items, props.dayWidth, props.stretchUpToday ])

  useEffect(() => {

    const getCurrentWeekLeft = (currentWeek: string) => {

      const found = weeks.find((week: any, i: number) => week.long === currentWeek)

      if (!found) return 0

      const firstDayIndex = days.findIndex((day: any) => day.longWeek === found.long)

      return firstDayIndex
    }

    const currentWeek = getYearWeekFromDate(new Date())

    const currentWeekLeft = getCurrentWeekLeft(currentWeek)

    scrollGanttToLeft(currentWeekLeft + 1)
  }, [ days, weeks ])

  useEffect(() => {

    const handleKeyDown = (e: KeyboardEvent) => {

      if (e.key === 'Shift') {
        e.preventDefault()
        e.stopPropagation()

        shiftPressedRef.current = true
      }
    }

    const handleKeyUp = (e: KeyboardEvent) => {

      if (e.key === 'Shift') {
        shiftPressedRef.current = false
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return (
    <div className='gantt-container'>
      <div
        ref={ganttHoverContainerRef}
        className='gantt-row-hover-container d-none d-lg-block'
      >
        <div
          className="row-hover-content"
          style={{ height: contentHeight }}
        >
          <div ref={ganttHoverItemRef} className="row-hover-item"></div>
        </div>
      </div>
      <div className="gantt-frame"
        ref={frameRef}
        style={{ height: frameheight }}
      >
        <div className="gantt-table"
          style={{
            flexBasis: `${divisorPosition}%`
          }}
        >
          <div className="header">
            <div className="header-scroll" ref={tableHeaderRef}>
              <div className="tr"
                style={{
                  width: tableWidth
                }}
              >
                {columns.map((column, i) => (
                  <div
                    key={`${column.field}-${i}`}
                    className={`th ${props.showVerticalBorders ? 'with-border' : ''}`}
                    style={{
                      width: column.width
                    }}
                  >
                    {column.label}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="body custom-scrollbar"
            ref={tableBodyRef}
            onContextMenu={handleShowContextMenu}
            onScroll={(e: any) => onTableBodyScroll()}
            style={{
              position: 'relative'
            }}
          >
            <GanttTableBody
              items={visibleItems}
              columns={columns}
              selectedItems={selectedItems}
              showVerticalBorders={props.showVerticalBorders}
              onToggleCollapse={props.onToggleCollapse}
              onItemClick={handleItemClick}
              schema={props.schema}
              onUpdateOrder={props.onUpdateOrder}
              sortEnabled={props.sortEnabled && selectedItems.length === 1}
              rowHeight={props.rowHeight}
              height={contentHeight}
              onItemHover={handleItemMouseOver}
              onItemOut={() => {}}
            />
          </div>
        </div>
        <div className="gantt-chart">
          <div className="header">
            <div className="header-scroll"
              ref={chartHeaderRef}
            >
              <GanttChartHeader
                days={days}
                weeks={weeks}
                dayWidth={props.dayWidth}
              />
            </div>
          </div>
          <div className="body custom-scrollbar"
            ref={chartBodyRef}
            onScroll={(e: any) => onChartBodyScroll()}
          >
            <GanttChartBody
              days={days}
              weeks={weeks}
              items={visibleItems}
              selectedItems={selectedItems}
              dayWidth={props.dayWidth}
              rowHeight={props.rowHeight}
              height={contentHeight}
              showVerticalBorders={props.showVerticalBorders}
              useCodeColors={props.useCodeColors}
              schema={props.schema}
              lineBaseTasks={props.lineBaseTasks}
              onItemClick={handleItemClick}
              onItemHover={handleItemMouseOver}
            />
          </div>
        </div>
        <Divisor
          position={divisorPosition}
          frameLeft={frameLeft}
          frameWidth={frameWidth}
          minTableWidthPercent={divisorPosition < minTableWidthPercent ? divisorPosition : minTableWidthPercent}
          maxTableWidthPercent={maxTableWidthPercent}
          tableWidth={tableWidth}
          onScroll={setDivisorPosition}
          onScrollEnded={() => {

            props.onUpdateSetting('divisorPosition', Number(divisorPosition.toFixed(0)))
          }}
        />
        <div ref={sidebarRef}>
          <Sidebar
            schema={props.schema}
            show={props.showSidebar || false}
            dayWidth={props.dayWidth}
            maxDayWidth={props.maxDayWidth}
            rowHeight={props.rowHeight}
            maxRowHeight={props.maxRowHeight}
            canChangeRowHeight={props.schema === 'weekly'}
            columns={props.columns}
            hiddenColumns={props.columnsHidden}
            useCodeColors={props.useCodeColors}
            showVerticalBorders={props.showVerticalBorders}
            hideNonWorkingDays={props.hideNonWorkingDays}
            stretchUpToday={props.stretchUpToday}
            moveToScheduledDays={props.moveToScheduledDays}
            canShowBaseline={props.schema === 'macro'}
            lineBaseTasks={props.lineBaseTasks}
            onUpdateSetting={props.onUpdateSetting}
            onToggleColumn={handleToggleColumn}
            onShowWorkflowsTriggered={showWorkflows}
          />
        </div>
      </div>
    </div>
  )
}

export default Gantt
