import React, { Fragment } from 'react';
import useTranslation from '../../../Hooks/UseTranslation';



type Props = {
    isNotRead: boolean,
    icon: any,
    data: any,
    onClick: any,
    timeDifference: string

}

const AccountDeletedNotification = (props: Props) => {

    const { t, language } = useTranslation()

    return (
        <div className={`${props.isNotRead ? 'dropdownnotification' : ''} dropdown-item d-flex`}
            onClick={props.onClick}
        >
            <div className="notification-icon" style={{ padding: '10px' }}>
                {props.icon}
            </div>
            <div className="notification-details flex-grow-1">
                <p className="m-0 d-flex align-items-center">
                    <span>
                        {t('NOTIFICATIONS_ACCOUNT_DELETED:Project')}{' '}
                        <strong>{props.data.project.name}</strong>
                    </span>
                    <span
                        className={`badge badge-pill badge-muted ml-1 mr-1`}
                    >
                        {props.data.status === 'read' ? null : (
                            <span className="badge badge-pill badge-primary ml-1 mr-1">
                                {t('NOTIFICATIONS_ACCOUNT_DELETED:Pending')}
                            </span>
                        )}
                    </span>
                    <span className="flex-grow-1"></span>
                    <span className=" text-muted ml-auto">
                        {language === 'es' && (
                            <Fragment>
                                {t('NOTIFICATIONS_ACCOUNT_DELETED:Ago')}{' '}
                                {props.timeDifference}
                            </Fragment>
                        )}
                        {language === 'en' && (
                            <Fragment>
                                {props.timeDifference}{' '}
                                {t('NOTIFICATIONS_ACCOUNT_DELETED:Ago')}
                            </Fragment>
                        )}
                    </span>
                </p>
                <p className=" text-muted m-0">
                    <span className='notification-content'>
                        {t('NOTIFICATIONS_ACCOUNT_DELETED:TheUser')}{' '}
                        <strong>
                            {props.data.who.name} {props.data.who.surname}
                        </strong>{' '}
                        {t('NOTIFICATIONS_ACCOUNT_DELETED:HasDeletedTheirAccountAndCannotParticipateAnymoreIn')}{' '}
                        <strong>{props.data.project.name}</strong>
                    </span>
                </p>
            </div>
        </div>
    )
}

export default AccountDeletedNotification